import React from 'react';
import { Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "slices/app.slice";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function GlobalSnackbar() {
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.app.snackbar);

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={snackbar.autoHideDuration}
      onClose={() => dispatch(setSnackbar({ open: false }))}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={() => dispatch(setSnackbar({ open: false }))} severity={snackbar.severity} sx={{ width: '100%' }}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
}