import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { useFormik } from 'formik';
import { useDidUpdateMutation } from 'services/api';
import { useTranslation } from 'react-i18next';
import DidForm from '../didForm/DidForm';
import * as Yup from 'yup';


function DidUpdate(props) {
  const { t } = useTranslation();
  const [didUpdate, { isLoading, error }] = useDidUpdateMutation();
  const { values, setValues, open, setOpen } = props;

  const formik = useFormik({
    initialValues: {
      name: '',
      prefix: '',
      did: '',
      description: '',
      provider: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      prefix: Yup.object().required(),
      did: Yup.string().required(),
      description: Yup.string().required(),
      provider: Yup.object().required()
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
        prefix: values.prefix.id,
        provider: values.provider.id,
        did: values.did.replace(/\s+/g, '')
      };
      delete payload.id;
      delete payload.assigned;
      delete payload.verified;
      didUpdate(payload).unwrap().then(() => {
        close();
      }).catch(() => { });
    },
  });

  useEffect(() => {
    if (open) formik.setValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, open]);

  function close() {
    setOpen(false);
    formik.resetForm();
    setValues({});
  }

  return (
    <Dialog open={open} onClose={() => close()} fullWidth maxWidth="md">
      {isLoading && <LinearProgress />}
      <DialogTitle>{t("__did_update_title")}</DialogTitle>
      <DialogContent>
        <DidForm formik={formik} error={error?.data} />
      </DialogContent>
      <DialogActions sx={{ pr: 3, pl: 3, pb: 3 }}>
        <Button disabled={isLoading} onClick={() => close()}>{t("__cancel")}</Button>
        <Button type='submit' form="didUpdate" variant="outlined" disabled={isLoading} onClick={formik.handleSubmit}>{t("__accept")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DidUpdate;