import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { loadYupLocale } from './yup';
import { setLanguage } from 'slices/app.slice';
import { store } from 'app/store';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import es from 'styles/images/flags/es.svg';
import en from 'styles/images/flags/en.svg';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import 'dayjs/locale/en';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      lng: 'es',
      load: 'languageOnly',
      fallbackLng: 'es',
      whitelist: ['es', 'en'],
      debug: false,
      interpolation: {
        escapeValue: false,
      },
    },
  );

export function changeLanguage(newLng) {
  if (i18n.isInitialized && i18n.language !== newLng) {
    i18n.changeLanguage(newLng, loadYupLocale);
    dayjs.locale(newLng);
    store.dispatch(setLanguage(newLng));
  }
}

export function languages() {
  return [
    { code: 'es', flag: es },
    { code: 'en', flag: en }
  ]
}

i18n.on('initialized', () => {
  dayjs.locale('es');
  store.dispatch(setLanguage('es'));
});

export default i18n;