import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CampaignForm from '../campaignForm/CampaignForm';
import { useCampaignUpdateMutation } from 'services/api';
import * as Yup from 'yup';


function CampaignUpdate(props) {
  const { t } = useTranslation();
  const { values, setValues, open, setOpen } = props;
  const [campaignUpdate, { isLoading, error }] = useCampaignUpdateMutation();

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      status: '',
      domain: '',
      did: '',
      widget: '',
      schedule: '',
      services: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      description: Yup.mixed().required(),
      status: Yup.object().required(),
      domain: Yup.object().required(),
      did: Yup.object().required(),
      schedule: Yup.object().required(),
      widget: Yup.object().required(),
      services: Yup.number().required(),
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
        domain: values.domain?.uuid,
        did: values.did?.uuid,
        schedule: values.schedule?.uuid,
        widget: values.widget?.uuid,
        status: values.status?.id
      };
      delete payload.id;
      campaignUpdate(payload).unwrap().then(() => {
        close();
      }).catch(() => { });
    },
  });

  useEffect(() => {
    if (open) formik.setValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, open]);

  function close() {
    setOpen(false);
    formik.resetForm();
    setValues({});
  }

  return (
    <Dialog open={open} onClose={() => !isLoading && close()} fullWidth maxWidth="md">
      {isLoading && <LinearProgress />}
      <DialogTitle>{t("__campaign_update_title")}</DialogTitle>
      <DialogContent>
        <CampaignForm formik={formik} error={error?.data} />
      </DialogContent>
      <DialogActions sx={{ pr: 3, pl: 3, pb: 3 }}>
        <Button disabled={isLoading} onClick={() => close()}>{t("__cancel")}</Button>
        <Button variant="outlined" disabled={isLoading} onClick={formik.handleSubmit}>{t("__accept")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default CampaignUpdate;