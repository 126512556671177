import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Box, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useDidOtpSendMutation, useDidOtpVerifyMutation } from 'services/api';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'slices/app.slice';
import { getUser } from 'services/localStorage';
import * as Yup from 'yup';


function DidOTP(props) {
  const { t } = useTranslation();
  const [didOtpSend, { isLoading: isDidOtpSendLoading }] = useDidOtpSendMutation();
  const [didOtpVerify, { isLoading: isDidOtpVerifyLoading, error: didOtpVerifyError }] = useDidOtpVerifyMutation();
  const { values: did, setValues, open, setOpen } = props;
  const [reloadTimeout, setReloadTimeout] = React.useState(0);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: Yup.object({
      code: Yup.string().required(),
    }),
    onSubmit: (values) => {
      const payload = {
        didId: did.uuid,
        payload: values
      }
      didOtpVerify(payload).unwrap().then(() => {
        close();
      }).catch(() => { });
    },
  });

  useEffect(() => {
    const interval = setInterval(() => setReloadTimeout(timer => timer - 1), 1000);
    if (reloadTimeout === 0) clearInterval(interval);
    return () => clearInterval(interval);
  }, [reloadTimeout]);

  function close() {
    setOpen(false);
    formik.resetForm();
    setValues({});
  }

  function sendCode() {
    setReloadTimeout(15);
    const payload = {
      didId: did.uuid,
      payload: {
        client_id: getUser()?.clients?.[0]?.uuid
      }
    }
    didOtpSend(payload).unwrap().then(() => {
      dispatch(setSnackbar({ open: true, severity: 'success', message: t("__did_form_code_sended") }));
    }).catch(() => { });
  }

  return (
    <Dialog open={open} onClose={() => close()} fullWidth maxWidth="sm">
      {(isDidOtpVerifyLoading || isDidOtpSendLoading) && <LinearProgress />}
      <DialogTitle>{t("__did_otp_title")}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          id="didOTP"
          noValidate
          sx={{ mt: 1 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='body2' sx={{ color: 'gray' }}>{t("__did_otp_subtitle")}</Typography>
            </Grid>
            <Grid item xs={12}>
              {/* <Stack spacing={2} direction="column"> */}
              <TextField
                label={t("__did_form_code")}
                required
                autoFocus
                fullWidth
                {...formik.getFieldProps('code')}
                error={Boolean(formik.submitCount) && (Boolean(formik.errors.code) || Boolean(didOtpVerifyError?.error_details?.code))}
                helperText={Boolean(formik.submitCount) && (formik.errors.code || didOtpVerifyError?.error_details?.code)}
              />
              <Button variant="text" disabled={Boolean(reloadTimeout)} sx={{ mt: 2 }} onClick={() => sendCode()}>{t("__did_form_send_code") + (reloadTimeout > 0 ? ' (' + reloadTimeout + 's)' : '')}</Button>
              {/* </Stack> */}
            </Grid>
            {/* <Typography component="div" sx={{ color: 'error.main' }}>{error?.error}</Typography> */}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pr: 3, pl: 3, pb: 3 }}>
        <Button disabled={isDidOtpVerifyLoading} onClick={() => close()}>{t("__cancel")}</Button>
        <Button type='submit' form="didUpdate" variant="outlined" disabled={isDidOtpVerifyLoading} onClick={formik.handleSubmit}>{t("__verify")}</Button>
      </DialogActions>
    </Dialog >
  );
}

export default DidOTP;