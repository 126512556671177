import React, { useEffect } from 'react';
import { Grid, Paper, IconButton, LinearProgress, Tooltip } from '@mui/material';
import { useLazyGetReportQuery } from 'services/api';
import { getUser } from 'services/localStorage';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StatisticsFilter from './statisticsFilter/StatisticsFilter';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as mTZ from 'moment-timezone';
import * as _ from 'lodash';
import darkTheme from 'highcharts/themes/dark-unica';
window.moment = mTZ; // Required by Highcharts

require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/highcharts-more")(Highcharts);
require("highcharts/highcharts-3d")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/offline-exporting")(Highcharts);
require("highcharts/modules/boost")(Highcharts);
require("highcharts/modules/funnel")(Highcharts);
require("highcharts/modules/timeline")(Highcharts);

export function Statistics() {
  const reportIds = ['10101', '10102', '10103', '10104', '10105', '10106', '10107', '10108', '10109'];
  const reports = useSelector((state) => state.statistics.reports);
  const themeMode = useSelector((state) => state.app.theme);
  const filter = useSelector((state) => state.statistics.filter);
  const [showFilters, setShowFilters] = React.useState(false);
  const [reloadTimeout, setReloadTimeout] = React.useState(5);
  const [fetchReport] = useLazyGetReportQuery();
  const { t } = useTranslation();

  useEffect(() => {
    getReports();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (themeMode === 'dark') darkTheme(Highcharts);
  }, [themeMode]);

  useEffect(() => {
    const interval = setInterval(() => setReloadTimeout(timer => timer - 1), 1000);
    if (reloadTimeout === 0) clearInterval(interval);
    return () => clearInterval(interval);
  }, [reloadTimeout]);

  function getReports() {
    if (!Object.values(reports).some(report => report.loading)) {
      setReloadTimeout(5);
      reportIds.forEach((reportId) => {
        // dispatch(api.endpoints.getReport.initiate({ reportId: reportId, clientId: getUser()?.clients?.[0]?.uuid, filter: filter }));
        fetchReport({ reportId: reportId, clientId: getUser()?.clients?.[0]?.uuid, filter: btoa(JSON.stringify(filter)) });
      });
    }
  }

  function hasFilters() {
    let hasFilters = false;
    for (const [key, value] of Object.entries(filter)) {
      if ((key === 'date' && value?.interval !== 'today') || ((key === 'campaign' || key === 'did' || key === 'widget' || key === 'trackStatus') && Boolean(value.length))) hasFilters = true;
    }
    return hasFilters;
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', textAlign: 'right' }}>
          {/* <Button variant="text" onClick={() => setShowFilters(!showFilters)}><FilterAltOffOutlinedIcon sx={{ mr: 1 }} />Filtrar</Button> */}
          <IconButton
            size="large"
            onClick={(e) => getReports()}
            disabled={Boolean(reloadTimeout)}
          >
            <Tooltip title={t('__reload')}>
              <CachedOutlinedIcon />
            </Tooltip>
          </IconButton>
          <IconButton
            size="large"
            onClick={(e) => setShowFilters(!showFilters)}
          // color={true ? 'inherit' : 'primary'}
          >
            <Tooltip title={t('__filter')}>
              {hasFilters() ? <FilterAltOutlinedIcon color='primary' /> : <FilterAltOffOutlinedIcon />}
            </Tooltip>
          </IconButton>
        </Grid>

        {Object.keys(reports).map((reportId) =>
          reportIds.includes(reportId) &&
          <Grid key={reportId} item xs={12} sm={12} md={6} xl={4}>
            <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 0 }}>
              {reports[reportId]?.[0] === 'loading' && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
              {/* <Typography variant="h6">{reportId}</Typography> */}
              {/* <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__service_add_subtitle')}</Typography> */}
              {reports[reportId]?.[0] !== 'loading' &&
                <HighchartsReact key={reportId} highcharts={Highcharts} options={_.cloneDeep(reports[reportId])} />
              }
            </Paper>
          </Grid>
        )}
      </Grid>

      {/* FILTERS */}
      {showFilters &&
        <StatisticsFilter showFilters={showFilters} setShowFilters={setShowFilters} />
      }
    </>
  );
}

export default Statistics;
