import React, { Suspense } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Login } from './features/login/Login';
import { Service } from './features/dashboard/service/Service';
import { Did } from './features/dashboard/did/Did';
import { Campaign } from './features/dashboard/campaign/Campaign';
import { Schedule } from './features/dashboard/schedule/Schedule';
import { Account } from './features/dashboard/account/Account';
import { Statistics } from './features/dashboard/statistics/Statistics';
import { Recover } from './features/login/recover/Recover';
import { Widget } from 'features/dashboard/widget/Widget';
import { Track } from 'features/dashboard/track/Track';
import { Supervision } from 'features/dashboard/supervision/Supervision';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getTheme } from './styles/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { setTheme } from 'slices/app.slice';
import { useDispatch } from 'react-redux';
import { GlobalSnackbar } from 'features/shared/globalSnackbar/globalSnackbar';
import Dashboard from './features/dashboard/Dashboard';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import './App.scss';


const ProtectedRoute = ({ isAllowed, redirectPath = '/login', children, }) => {
  if (!isAllowed) return <Navigate to={redirectPath} replace />;
  return children ? children : <Outlet />;
};

function App() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const language = useSelector((state) => state.app.language);
  const themeMode = useSelector((state) => state.app.theme);

  const colorMode = React.useMemo(() => ({
    // The dark mode switch would invoke this method
    toggleColorMode: () => {
      dispatch(setTheme((prevMode) =>
        prevMode === 'light' ? 'dark' : 'light',
      ));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const ColorModeContext = React.createContext({
    toggleColorMode: () => {
      // This is intentional
    },
  });

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(getTheme(themeMode)), [themeMode]);

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback="loading">
              <Routes>
                <Route index element={<Login />} />
                <Route path="login" element={<Login />} />
                <Route path="login/recover/:token" element={<Recover />} />
                <Route path="dashboard" element={<ProtectedRoute isAllowed={Boolean(token)}><Dashboard /></ProtectedRoute>}>
                  <Route index path="campaigns" element={<Campaign />} />
                  <Route index path="services" element={<Service />} />
                  <Route index path="did" element={<Did />} />
                  <Route index path="schedule" element={<Schedule />} />
                  <Route index path="supervision" element={<Supervision />} />
                  <Route index path="analytics" element={<Statistics />} />
                  <Route index path="widget" element={<Widget />} />
                  <Route index path="track" element={<Track />} />
                  <Route index path="account" element={<Account />} />
                </Route>
              </Routes>
              <GlobalSnackbar />
            </Suspense>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;
