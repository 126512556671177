import React from 'react';
import { TextField, Box, Grid, Autocomplete } from '@mui/material';
import { useCountryPrefixListQuery, useProviderListQuery } from 'services/api';
import { IMaskInput } from 'react-imask';
import { useTranslation } from 'react-i18next';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  return (
    <IMaskInput
      {...props}
      mask={props.placeholder}
      inputRef={ref}
      onAccept={(value) => props.onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

function DidForm(props) {
  const { t } = useTranslation();
  const { formik, error } = props;
  const { data: countryPrefixList, isFetching: isCountryPrefixListFetching } = useCountryPrefixListQuery();
  const { data: providerList, isFetching: isProviderListFetching } = useProviderListQuery();

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      id="didForm"
      noValidate
      sx={{ mt: 1 }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="name"
            label={t("__did_form_name")}
            required
            fullWidth
            autoFocus
            {...formik.getFieldProps('name')}
            error={Boolean(formik.submitCount) && (Boolean(formik.errors.name) || Boolean(error?.error_details?.name))}
            helperText={Boolean(formik.submitCount) && (formik.errors.name || error?.error_details?.name)}
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            options={countryPrefixList?.data?.prefixes || []}
            autoHighlight
            getOptionLabel={(option) => '(+' + option.country_code + ') ' + option.country}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            loading={isCountryPrefixListFetching}
            loadingText={t('__loading')}
            noOptionsText={t('__no_options')}
            value={formik.values.prefix || null}
            onChange={(e, value) => {
              formik.setFieldValue(`prefix`, value);
              formik.setFieldValue(`did`, '');
            }}
            renderOption={(props, option) => (
              <Box key={option?.id} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option?.iso_code?.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option?.iso_code?.toLowerCase()}.png 2x`}
                  alt=""
                />
                (+{option?.country_code}) {option?.country}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={t('__prefix')}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
                error={Boolean(formik.submitCount) && (Boolean(formik.errors.prefix) || Boolean(error?.error_details?.prefix))}
                helperText={Boolean(formik.submitCount) && (formik.errors.prefix || error?.error_details?.prefix)}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            id="did"
            label={t("__did_form_did")}
            required
            fullWidth
            InputProps={{
              inputComponent: TextMaskCustom,
            }}
            {...formik.getFieldProps('did')}
            placeholder={formik.values?.prefix?.mask}
            error={Boolean(formik.submitCount) && (Boolean(formik.errors.did) || Boolean(error?.error_details?.did))}
            helperText={Boolean(formik.submitCount) && (formik.errors.did || error?.error_details?.did)}
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            options={providerList?.data?.providers || []}
            getOptionLabel={(label) => t(label?.name_t) || ''}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            loading={isProviderListFetching}
            loadingText={t('__loading')}
            noOptionsText={t('__no_options')}
            value={formik.values.provider || null}
            onChange={(e, value) => formik.setFieldValue(`provider`, value)}
            renderInput={(params) => <TextField
              {...params}
              required
              label={t('__did_form_provider')}
              error={Boolean(formik.submitCount) && Boolean(formik.errors.provider)}
              helperText={Boolean(formik.submitCount) && formik.errors.provider}
            />}
            renderOption={(props, option) => (
              <li {...props} key={option?.id}>
                {t(option?.name_t)}
              </li>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="description"
            label={t("__did_form_description")}
            required
            fullWidth
            multiline
            minRows={2}
            {...formik.getFieldProps('description')}
            error={Boolean(formik.submitCount) && (Boolean(formik.errors.description) || Boolean(error?.error_details?.description))}
            helperText={Boolean(formik.submitCount) && (formik.errors.description || error?.error_details?.description)}
          />
        </Grid>

        {/* <Typography component="div" sx={{ color: 'error.main' }}>{error?.error}</Typography> */}
      </Grid>
    </Box>

  );
}

export default DidForm;