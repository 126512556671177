import React, { useState } from 'react';
import { Avatar, Button, TextField, Link, Grid, Box, Typography, Container, Paper } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { useLoginMutation, useLazyUserCurrentQuery } from 'services/api';
import { Forgot } from './forgot/Forgot';
import { useTranslation } from 'react-i18next';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import * as Yup from 'yup';

export function Login() {
  const { t } = useTranslation();
  const [login, { isLoading: isLoginLoading, error: loginError }] = useLoginMutation();
  const [fetchUserCurrent] = useLazyUserCurrentQuery();
  const [openRecover, setOpenRecover] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      pass: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(),
      pass: Yup.string().required(),
    }),
    onSubmit: (values) => {
      const payload = { ...values };
      login(payload).unwrap().then(() => {
        fetchUserCurrent();
        navigate('/dashboard/services');
      }).catch(() => { });
    }
  });

  return (
    <Container component="main" maxWidth="sm" sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
      <Box>
        <Paper sx={{ display: 'flex', height: '100%', flexDirection: 'column', alignContent: 'center', boxShadow: 'rgba(0, 0, 0, 0.08) 0px 6px 30px', borderRadius: '20px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginLeft: 3,
              marginRight: 3,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t('__login_title')}
            </Typography>
            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                id="email"
                type='email'
                label={t('__login_form_email')}
                name="email"
                required
                fullWidth
                autoComplete="email"
                autoFocus
                margin="normal"
                {...formik.getFieldProps('email')}
                error={Boolean(formik.submitCount) && (Boolean(formik.errors.email) || Boolean(loginError?.data?.error_details?.email))}
                helperText={Boolean(formik.submitCount) && (formik.errors.email || loginError?.data?.error_details?.email)}
              />
              <TextField
                id="password"
                label={t('__login_form_password')}
                type="password"
                name="password"
                required
                fullWidth
                margin="normal"
                {...formik.getFieldProps('pass')}
                error={Boolean(formik.submitCount) && (Boolean(formik.errors.pass) || Boolean(loginError?.data?.error_details?.pass))}
                helperText={Boolean(formik.submitCount) && (formik.errors.pass || loginError?.data?.error_details?.pass)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isLoginLoading}
              >
                {t('__login_button_submit')}
              </Button>
              <Grid container>
                <Grid item xs sx={{ textAlign: 'center' }}>
                  <Link
                    type="button"
                    component="button"
                    underline="none"
                    variant="body2"
                    onClick={() => setOpenRecover(true)}
                  >
                    {t('__login_forgot_password')}
                  </Link>
                </Grid>
                {/* <Grid item>
                  <Link href="#">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid> */}
              </Grid>
            </Box>
          </Box>
        </Paper>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Box>
      <Forgot open={openRecover} setOpen={setOpenRecover} />
    </Container>
  );
}