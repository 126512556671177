export const daysOfWeek = [
  { id: 1, name_t: '__monday' },
  { id: 2, name_t: '__tuesday' },
  { id: 3, name_t: '__wednesday' },
  { id: 4, name_t: '__thursday' },
  { id: 5, name_t: '__friday' },
  { id: 6, name_t: '__saturday' },
  { id: 7, name_t: '__sunday' },
];

export const dateInterval = [
  { id: 1, name: 'today', name_t: '__today' },
  { id: 2, name: 'custom', name_t: '__custom' },
  { id: 3, name: 'last30m', name_t: '__last30m' },
  { id: 4, name: 'lastHour', name_t: '__lastHour' },
  { id: 5, name: 'yesterday', name_t: '__yesterday' },
  { id: 6, name: 'thisWeek', name_t: '__thisWeek' },
  { id: 7, name: 'last7d', name_t: '__last7d' },
  { id: 8, name: 'thisMonth', name_t: '__thisMonth' },
  { id: 9, name: 'lastMonth', name_t: '__lastMonth' },
  { id: 10, name: 'last30d', name_t: '__last30d' },
  { id: 11, name: 'last3Months', name_t: '__last3Months' },
  { id: 12, name: 'last6Months', name_t: '__last6Months' },
  { id: 13, name: 'lastYear', name_t: '__lastYear' },
  { id: 14, name: 'last2Years', name_t: '__last2Years' },
  { id: 15, name: 'last3Years', name_t: '__last3Years' },
  { id: 16, name: 'last4Years', name_t: '__last4Years' },
  { id: 17, name: 'last5Years', name_t: '__last5Years' },
]

// Track status
// '1', 'IMPRESSION', 'Service was assigned', '2023-02-27 12:41:00', '2023-02-27 12:41:00', '__impression'
// '2', 'CLICK', 'click', '2023-02-27 12:41:00', '2023-02-27 12:41:00', '__click'
// '3', 'BUSY', 'All the services are been used', '2023-02-27 12:41:00', '2023-02-27 12:41:00', '__busy'
// '4', 'OUT_OF_TIME', 'Out of campaign time / schedule', '2023-02-27 16:57:31', '2023-02-27 16:57:31', '__out_of_time'
// '5', 'IN_PROGRESS', 'call in progress', '2023-02-27 12:41:00', '2023-02-27 12:41:00', '__in_progress'
// '6', 'RANKING', 'ranking', '2023-02-27 12:41:00', '2023-02-27 12:41:00', '__ranking'
// '7', 'DONE', 'done', '2023-02-27 12:41:00', '2023-02-27 12:41:00', '__done'
// '8', 'END', 'end', '2023-02-27 12:41:00', '2023-02-27 12:41:00', '__end'
// '9', 'IN_CALL', 'in call', '2023-02-27 12:41:00', '2023-02-27 12:41:00', '__in_call'
// '99', 'ERROR', 'error', '2023-02-27 12:41:00', '2023-02-27 12:41:00', '__error'