import React from 'react';
import { TextField, Box, Grid, Autocomplete } from '@mui/material';
import { useDomainListQuery, useDidListQuery, useScheduleListQuery, useCampaignStatusQuery, useWidgetListQuery } from 'services/api';
import { useTranslation } from 'react-i18next';


function CampaignForm(props) {
  const { t } = useTranslation();
  const { formik, error } = props;
  const { data: domainList, isFetching: isDomainListFetching } = useDomainListQuery();
  const { data: didList, isFetching: isDidListFetching } = useDidListQuery();
  const { data: scheduleList, isFetching: isScheduleListFetching } = useScheduleListQuery();
  const { data: widgetList, isFetching: isWidgetListFetching } = useWidgetListQuery();
  const { data: campaignStatus, isFetching: isCampaignStatusFetching } = useCampaignStatusQuery();

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      id="campaignForm"
      noValidate
      sx={{ mt: 1 }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="name"
            label={t("__campaign_form_name")}
            required
            fullWidth
            autoFocus
            {...formik.getFieldProps('name')}
            error={Boolean(formik.submitCount) && (Boolean(formik.errors.name) || Boolean(error?.error_details?.name))}
            helperText={Boolean(formik.submitCount) && (formik.errors.name || error?.error_details?.name)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="description"
            label={t("__campaign_form_description")}
            required
            fullWidth
            multiline
            minRows={2}
            {...formik.getFieldProps('description')}
            error={Boolean(formik.submitCount) && (Boolean(formik.errors.description) || Boolean(error?.error_details?.description))}
            helperText={Boolean(formik.submitCount) && (formik.errors.description || error?.error_details?.description)}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            id='domain'
            options={domainList?.data?.domains || []}
            getOptionLabel={(label) => label?.name || ''}
            isOptionEqualToValue={(option, value) => option?.uuid === value?.uuid}
            loading={isDomainListFetching}
            loadingText={t('__loading')}
            noOptionsText={t('__no_options')}
            value={formik.values.domain || null}
            onChange={(e, value) => formik.setFieldValue(`domain`, value)}
            renderInput={(params) => <TextField
              {...params}
              label={t('__campaign_form_domain')}
              error={Boolean(formik.submitCount) && (Boolean(formik.errors.domain) || Boolean(error?.error_details?.domain))}
              helperText={Boolean(formik.submitCount) && (formik.errors.domain || error?.error_details?.domain)}
            />}
            renderOption={(props, option) => (
              <li {...props} key={option.uuid}>
                {option.name}
              </li>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="did"
            options={didList?.data?.dids || []}
            getOptionLabel={(label) => label?.name || ''}
            isOptionEqualToValue={(option, value) => option?.uuid === value?.uuid}
            loading={isDidListFetching}
            loadingText={t('__loading')}
            noOptionsText={t('__no_options')}
            value={formik.values.did || null}
            onChange={(e, value) => formik.setFieldValue(`did`, value)}
            renderInput={(params) => <TextField
              {...params}
              label={t('__campaign_form_did')}
              error={Boolean(formik.submitCount) && (Boolean(formik.errors.did) || Boolean(error?.error_details?.did))}
              helperText={Boolean(formik.submitCount) && (formik.errors.did || error?.error_details?.did)}
            />}
            renderOption={(props, option) => (
              <li {...props} key={option.uuid}>
                {option.name}
              </li>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="schedule"
            options={scheduleList?.data?.schedules || []}
            getOptionLabel={(label) => label?.name || ''}
            isOptionEqualToValue={(option, value) => option?.uuid === value?.uuid}
            loading={isScheduleListFetching}
            loadingText={t('__loading')}
            noOptionsText={t('__no_options')}
            value={formik.values.schedule || null}
            onChange={(e, value) => formik.setFieldValue(`schedule`, value)}
            renderInput={(params) => <TextField
              {...params}
              label={t('__campaign_form_schedule')}
              error={Boolean(formik.submitCount) && (Boolean(formik.errors.schedule) || Boolean(error?.error_details?.schedule))}
              helperText={Boolean(formik.submitCount) && (formik.errors.schedule || error?.error_details?.schedule)}
            />}
            renderOption={(props, option) => (
              <li {...props} key={option.uuid}>
                {option.name}
              </li>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="widget"
            options={widgetList?.data?.widgets || []}
            getOptionLabel={(label) => label?.name || ''}
            isOptionEqualToValue={(option, value) => option?.uuid === value?.uuid}
            loading={isWidgetListFetching}
            loadingText={t('__loading')}
            noOptionsText={t('__no_options')}
            value={formik.values.widget || null}
            onChange={(e, value) => formik.setFieldValue(`widget`, value)}
            renderInput={(params) => <TextField
              {...params}
              label={t('__campaign_form_widget')}
              error={Boolean(formik.submitCount) && (Boolean(formik.errors.widget) || Boolean(error?.error_details?.widget))}
              helperText={Boolean(formik.submitCount) && (formik.errors.widget || error?.error_details?.widget)}
            />}
            renderOption={(props, option) => (
              <li {...props} key={option.uuid}>
                {option.name}
              </li>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="status"
            options={campaignStatus?.data?.campaign_status || []}
            getOptionLabel={(label) => t(label?.name_t) || ''}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            loading={isCampaignStatusFetching}
            loadingText={t('__loading')}
            noOptionsText={t('__no_options')}
            value={formik.values.status || null}
            onChange={(e, value) => formik.setFieldValue(`status`, value)}
            renderInput={(params) => <TextField
              {...params}
              label={t('__campaign_form_status')}
              error={Boolean(formik.submitCount) && (Boolean(formik.errors.status) || Boolean(error?.error_details?.status))}
              helperText={Boolean(formik.submitCount) && (formik.errors.status || error?.error_details?.status)}
            />}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {t(option?.name_t)}
              </li>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="services"
            type="number"
            label={t("__campaign_form_services")}
            required
            fullWidth
            {...formik.getFieldProps('services')}
            error={Boolean(formik.submitCount) && (Boolean(formik.errors.services) || Boolean(error?.error_details?.services))}
            helperText={Boolean(formik.submitCount) && (formik.errors.services || error?.error_details?.services)}
          />
        </Grid>

        {/* <Typography component="div" sx={{ color: 'error.main' }}>{error?.error}</Typography> */}
      </Grid>
    </Box>
  );
}

export default CampaignForm;