import React from 'react';
import { Grid, Box, Button, Paper, LinearProgress, Typography, CircularProgress, FormControl, FormGroup, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useUserUpdateMutation } from 'services/api';
import { common } from "@mui/material/colors";
import { useTranslation } from 'react-i18next';
import { getUser } from 'services/localStorage';
import * as Yup from 'yup';


export function Account() {
  const [userUpdate, { isLoading: isUserUpdateLoading, error: userUpdateError }] = useUserUpdateMutation();
  const { t } = useTranslation();

  const formikUserUpdate = useFormik({
    initialValues: {
      password: '',
      password_repeat: ''
    },
    validationSchema: Yup.object({
      password: Yup
        .string()
        .min(8)
        .max(16)
        .matches(new RegExp("^(?=.*[a-z]).+$"), t("__validation_lowercase"))
        .matches(new RegExp("^(?=.*[A-Z]).+$"), t("__validation_uppercase"))
        .matches(new RegExp(".*[0-9].*"), t("__validation_digit"))
        .matches(new RegExp("^(?=.*[@#$%^&+=]).*$"), t("__validation_special", { value: '@#$%^&+=' }))
        .required(),
      password_repeat: Yup.string().required().oneOf([Yup.ref('password')], t("__validation_password_missmatch"))
    }),
    onSubmit: (values) => {
      const payload = {
        password: values.password,
        email: getUser()?.email,
        roles: getUser()?.roles?.map((role) => role?.id)
      };
      userUpdate(payload);
      formikUserUpdate.resetForm();
    },
  });

  return (
    <Grid container spacing={3}>

      {/* CHANGE PASSWORD */}
      <Grid item xs={12} sm={12} md={6}>
        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          {isUserUpdateLoading && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
          <Typography variant="h6">{t('__account_change_password_title')}</Typography>
          {/* <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__service_add_subtitle')}</Typography> */}
          <Box
            component="form"
            onSubmit={formikUserUpdate.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <FormControl margin="normal" fullWidth>
              <FormGroup>
                <TextField
                  id="password"
                  label={t('__login_form_password')}
                  type="password"
                  name="password"
                  required
                  fullWidth
                  margin="normal"
                  {...formikUserUpdate.getFieldProps('password')}
                  error={Boolean(formikUserUpdate.submitCount) && (Boolean(formikUserUpdate.errors.password) || Boolean(userUpdateError?.data?.error_details?.password))}
                  helperText={Boolean(formikUserUpdate.submitCount) && (formikUserUpdate.errors.password || userUpdateError?.data?.error_details?.password)}
                />
                <TextField
                  id="password_repeat"
                  label={t('__login_form_password_repeat')}
                  type="password"
                  name="password_repeat"
                  required
                  fullWidth
                  margin="normal"
                  {...formikUserUpdate.getFieldProps('password_repeat')}
                  error={Boolean(formikUserUpdate.submitCount) && (Boolean(formikUserUpdate.errors.password_repeat) || Boolean(userUpdateError?.data?.error_details?.password_repeat))}
                  helperText={Boolean(formikUserUpdate.submitCount) && (formikUserUpdate.errors.password_repeat || userUpdateError?.data?.error_details?.password_repeat)}
                />
                <Box>
                  <Button type='submit' variant="contained" disabled={isUserUpdateLoading} sx={{ mt: 2 }}>
                    {isUserUpdateLoading ?
                      <CircularProgress size={24} sx={{ color: common.white }} />
                      :
                      t('__account_change_password_button')
                    }
                  </Button>
                </Box>
              </FormGroup>
            </FormControl>
          </Box>
        </Paper>
      </Grid>

    </Grid>
  );
}

export default Account;

