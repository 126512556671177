import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import WidgetForm from '../widgetForm/WidgetForm';
import { useWidgetUpdateMutation } from 'services/api';
import * as Yup from 'yup';


function WidgetUpdate(props) {
  const { t } = useTranslation();
  const { values, setValues, open, setOpen } = props;
  const [widgetUpdate, { isLoading, error }] = useWidgetUpdateMutation();

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      config: {
        colors: {
          main: "",
          success: "",
          error: ""
        },
        callStatus: {
          form: {
            show: false,
            media: "",
            title: "",
            subtitle: "",
            fields: [],
            button_send: ""
          },
          standBy: {
            media: "",
            title: "",
            subtitle: "",
            button_request_call: ""
          },
          ringing: {
            media: "",
            title: "",
            subtitle: "",
            button_pick_up: "",
            button_hang_up: ""
          },
          inProgress: {
            media: "",
            title: "",
            subtitle: "",
            button_hang_up: ""
          },
          completed: {
            media: "",
            title: "",
            subtitle: "",
            button_send: ""
          }
        }
      }
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      description: Yup.string().required(),
      config: Yup.object({
        colors: Yup.object({
          main: Yup.string().required(),
          success: Yup.string().required(),
          error: Yup.string().required(),
        }),
        callStatus: Yup.object({
          form: Yup.object({
            show: Yup.boolean(),
            media: Yup.string().required(),
            title: Yup.string().required(),
            subtitle: Yup.string().required(),
            fields: Yup.array(),
            button_send: Yup.string().required(),
          }),
          standBy: Yup.object({
            media: Yup.string().required(),
            title: Yup.string().required(),
            subtitle: Yup.string().required(),
            button_request_call: Yup.string().required(),
          }),
          ringing: Yup.object({
            media: Yup.string().required(),
            title: Yup.string().required(),
            subtitle: Yup.string().required(),
            button_pick_up: Yup.string(),
            button_hang_up: Yup.string(),
          }),
          inProgress: Yup.object({
            media: Yup.string().required(),
            title: Yup.string().required(),
            subtitle: Yup.string().required(),
            button_hang_up: Yup.string(),
          }),
          completed: Yup.object({
            media: Yup.string().required(),
            title: Yup.string().required(),
            subtitle: Yup.string().required(),
            button_send: Yup.string().required(),
          }),
        }),
      }),
    }),
    onSubmit: (values) => {
      const payload = { ...values };
      delete payload.assigned;
      widgetUpdate(payload).unwrap().then(() => {
        close();
      }).catch(() => { });
    },
  });

  useEffect(() => {
    if (open) formik.setValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, open]);

  function close() {
    setOpen(false);
    formik.resetForm();
    setValues({});
  }

  return (
    <Dialog open={open} onClose={() => !isLoading && close()} fullWidth maxWidth="xl">
      {isLoading && <LinearProgress />}
      <DialogTitle>{t("__widget_update_title")}</DialogTitle>
      <DialogContent>
        <WidgetForm formik={formik} error={error?.data} />
      </DialogContent>
      <DialogActions sx={{ pr: 3, pl: 3, pb: 3 }}>
        <Button disabled={isLoading} onClick={() => close()}>{t("__cancel")}</Button>
        <Button variant="outlined" disabled={isLoading} onClick={formik.handleSubmit}>{t("__accept")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default WidgetUpdate;