import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, LinearProgress, DialogContent, DialogContentText, } from '@mui/material';
import { useWidgetDeleteMutation, useWidgetDeleteAllMutation } from 'services/api';
import { useTranslation } from 'react-i18next';


function WidgetDelete(props) {
  const { t } = useTranslation();
  const { values, setValues, valuesIds, open, setOpen } = props;
  const [widgetDelete, { isFetching: isWidgetDeleting }] = useWidgetDeleteMutation();
  const [widgetDeleteAll, { isFetching: isWidgetsDeletingAll }] = useWidgetDeleteAllMutation();

  function confirmDelete() {
    if (!Object.keys(values)?.length) widgetDeleteAll(valuesIds).unwrap().then(() => close()).catch(() => { });
    else widgetDelete(values.uuid).unwrap().then(() => close()).catch(() => { });
  }

  function close() {
    setOpen(false);
    setValues({});
  }

  return (
    <Dialog open={open} onClose={() => !isWidgetDeleting && !isWidgetsDeletingAll && close()} fullWidth maxWidth="md">
      {(isWidgetDeleting || isWidgetsDeletingAll) && <LinearProgress />}
      <DialogTitle>{t("__widget_delete_title")}</DialogTitle>
      <DialogContent>
        {!Object.keys(values)?.length ?
          <DialogContentText>
            {t('__widget_delete_all_description', { value: valuesIds?.length })}
          </DialogContentText>
          :
          <>
            <DialogContentText>
              {values?.name}
            </DialogContentText>
            <DialogContentText>
              {values?.description}
            </DialogContentText>
          </>
        }
      </DialogContent>
      <DialogActions>
        <Button disabled={isWidgetDeleting || isWidgetsDeletingAll} onClick={() => close()}>{t("__cancel")}</Button>
        <Button color="error" variant="outlined" disabled={isWidgetDeleting || isWidgetsDeletingAll} onClick={() => confirmDelete()}>{t("__delete")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default WidgetDelete;