import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, LinearProgress, DialogContent, DialogContentText, } from '@mui/material';
import { useDidDeleteMutation, useDidDeleteAllMutation } from 'services/api';
import { useTranslation } from 'react-i18next';


function DidDelete(props) {
  const { t } = useTranslation();
  const { values, setValues, valuesIds, open, setOpen } = props;
  const [didDelete, { isFetching: isDidDeleting }] = useDidDeleteMutation();
  const [didDeleteAll, { isFetching: isDidsDeletingAll }] = useDidDeleteAllMutation();

  function confirmDelete() {
    if (!Object.keys(values)?.length) didDeleteAll(values?.uuid).unwrap().then(() => close()).catch(() => { });
    else didDelete(values?.uuid).unwrap().then(() => close()).catch(() => { });
  }

  function close() {
    setOpen(false);
    setValues({});
  }

  return (
    <Dialog open={open} onClose={() => !isDidDeleting && !isDidsDeletingAll && close()} fullWidth maxWidth="md">
      {(isDidDeleting || isDidsDeletingAll) && <LinearProgress />}
      <DialogTitle>{t("__did_delete_title")}</DialogTitle>
      <DialogContent>
        {!Object.keys(values)?.length ?
          <DialogContentText>
            {t('__did_delete_all_description', { value: valuesIds?.length })}
          </DialogContentText>
          :
          <>
            <DialogContentText>
              {values?.name}
            </DialogContentText>
            <DialogContentText>
              {values?.did}
            </DialogContentText>
            <DialogContentText>
              {values?.description}
            </DialogContentText>
          </>
        }
      </DialogContent>
      <DialogActions>
        <Button disabled={isDidDeleting || isDidsDeletingAll} onClick={() => close()}>{t("__cancel")}</Button>
        <Button color="error" variant="outlined" disabled={isDidDeleting || isDidsDeletingAll} onClick={() => confirmDelete()}>{t("__delete")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DidDelete;