import React from 'react';
import { TextField, Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

function ServiceForm(props) {
  const { t } = useTranslation();
  const { formik, error } = props;

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      id="serviceForm"
      noValidate
      sx={{ mt: 1 }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="services"
            type='number'
            label={t("__service_form_services")}
            required
            fullWidth
            autoFocus
            {...formik.getFieldProps('services')}
            error={Boolean(formik.submitCount) && (Boolean(formik.errors.services) || Boolean(error?.error_details?.services))}
            helperText={Boolean(formik.submitCount) && (formik.errors.services || error?.error_details?.services)}
          />
        </Grid>

        {/* <Typography component="div" sx={{ color: 'error.main' }}>{error?.error}</Typography> */}
      </Grid>
    </Box>
  );
}

export default ServiceForm;