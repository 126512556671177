import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid, TextField } from '@mui/material';
import { getUser } from 'services/localStorage';
import { setSnackbar } from 'slices/app.slice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';


function CampaignScript(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { values, open, setOpen } = props;
  const [script, setScript] = React.useState('');

  useEffect(() => {
    if (open) {
      setScript(`<script>
  window.phone_webui_config = {
    client: "${getUser()?.clients?.[0]?.uuid}",
    campaign: "${values?.uuid}"
  }
</script>
<script src="${process.env.REACT_APP_WIDGET_URL}/bundle.js"></script>`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function copyScript() {
    setTimeout(() => {
      let copyText = document.getElementById("scriptWebChat");
      if (copyText) {
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
        document.execCommand("copy");
        dispatch(setSnackbar({ open: true, severity: 'success', message: t("__copied_to_clipboard") }));
      }
    }, 0);
  }

  function close() {
    setOpen(false);
  }

  return open && (
    <Dialog open={open} onClose={() => close()} fullWidth maxWidth="sm">
      <DialogTitle>{t("__campaign_script_title")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2">{t("__campaign_script_subtitle")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="scriptWebChat"
              required
              sx={{ width: '100%' }}
              // fullWidth
              multiline
              rows={7}
              value={script || ''}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ pr: 3, pl: 3, pb: 3 }}>
        <Button onClick={() => close()}>{t("__close")}</Button>
        <Button variant="outlined" onClick={() => copyScript()}>{t("__copy_to_clipboard")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default CampaignScript;