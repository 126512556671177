import React from 'react';
import { Avatar, Button, TextField, Box, Typography, Container, Paper } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from 'formik';
import { useLoginRecoverMutation } from 'services/api';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'slices/app.slice';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import * as Yup from 'yup';

export function Recover() {
  const { t } = useTranslation();
  const [loginRecover, { isLoading: isLoginRecoverLoading, error: loginRecoverError }] = useLoginRecoverMutation();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: '',
      password_repeat: ''
    },
    validationSchema: Yup.object({
      password: Yup
        .string()
        .min(8)
        .max(16)
        .matches(new RegExp("^(?=.*[a-z]).+$"), t("__validation_lowercase"))
        .matches(new RegExp("^(?=.*[A-Z]).+$"), t("__validation_uppercase"))
        .matches(new RegExp(".*[0-9].*"), t("__validation_digit"))
        .matches(new RegExp("^(?=.*[@#$%^&+=]).*$"), t("__validation_special", { value: '@#$%^&+=' }))
        .required(),
      password_repeat: Yup.string().required().oneOf([Yup.ref('password')], t("__validation_password_missmatch"))
    }),
    onSubmit: (values) => {
      const payload = {
        token: params?.token,
        payload: {
          password: values.password
        }
      };
      loginRecover(payload).unwrap().then(() => {
        navigate('/login');
        dispatch(setSnackbar({ open: true, severity: 'success', message: t("__login_recover_success_message") }));

      }).catch((error) => {
        dispatch(setSnackbar({ open: true, severity: 'error', message: error?.data?.error?.message || t("__login_recover_error_message") }));
      });
    }
  });

  return (
    <Container component="main" maxWidth="sm" sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
      <Box>
        <Paper sx={{ display: 'flex', height: '100%', flexDirection: 'column', alignContent: 'center', boxShadow: 'rgba(0, 0, 0, 0.08) 0px 6px 30px', borderRadius: '20px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginLeft: 3,
              marginRight: 3,
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">{t('__login_recover_title')}</Typography>
            <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__login_recover_subtitle')}</Typography>
            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                label={t('__login_form_password')}
                type="password"
                name="password"
                required
                fullWidth
                margin="normal"
                {...formik.getFieldProps('password')}
                error={Boolean(formik.submitCount) && (Boolean(formik.errors.password) || Boolean(loginRecoverError?.data?.error_details?.password))}
                helperText={Boolean(formik.submitCount) && (formik.errors.password || loginRecoverError?.data?.error_details?.password)}
              />
              <TextField
                label={t('__login_form_password_repeat')}
                type="password"
                name="password_repeat"
                required
                fullWidth
                margin="normal"
                {...formik.getFieldProps('password_repeat')}
                error={Boolean(formik.submitCount) && (Boolean(formik.errors.password_repeat) || Boolean(loginRecoverError?.data?.error_details?.password_repeat))}
                helperText={Boolean(formik.submitCount) && (formik.errors.password_repeat || loginRecoverError?.data?.error_details?.password_repeat)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isLoginRecoverLoading}
              >
                {t('__login_recover_button_submit')}
              </Button>
            </Box>
          </Box>
        </Paper>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Box>
    </Container>
  );
}