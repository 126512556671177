import { createSlice } from '@reduxjs/toolkit';
import { api } from 'services/api';
import { getToken as getStorageToken, setToken as setStorageToken, removeToken as removeStorageToken, getUser as getStorageUser, setUser as setStorageUser, removeUser as removeStorageUser } from 'services/localStorage';

const initialState = {
  token: getStorageToken(),
  user: getStorageUser()
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    removeAuth: (state) => {
      removeStorageToken();
      removeStorageUser();
      state.token = null;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.login.matchFulfilled, (state, { payload }) => {
      setStorageToken(payload.data.token);
      state.token = payload.data.token;
    });
    builder.addMatcher(api.endpoints.userCurrent.matchFulfilled, (state, { payload }) => {
      setStorageUser(payload.data.user);
      state.user = payload.data.user;
    });
  },
});

export const selectToken = (state) => state.auth.token;

export const { removeAuth } = authSlice.actions;

export default authSlice.reducer;
