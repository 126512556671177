import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { removeAuth } from 'slices/auth.slice';
import { setSnackbar } from 'slices/app.slice';
import i18next from 'i18next';

const baseQuery = fetchBaseQuery({
  baseUrl: '',
  prepareHeaders: (headers, { getState }) => {
    const token = getState()?.auth?.token;
    if (token) headers.set('authorization', `Bearer ${token}`);
    if (i18next.language) headers.set('Accept-Language', i18next.language);
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if ((result?.meta?.request?.method === "POST" || result?.meta?.request?.method === "PATCH" || result?.meta?.request?.method === "DELETE") && result?.meta?.response?.status === 200) {
    if (!result?.meta?.request?.url?.includes('api/login')) api.dispatch(setSnackbar({ open: true, severity: 'success', message: i18next.t("__changes_saved") }));
  }

  return result;
}

export const logger = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action?.payload?.data?.code > 500) {
      api.dispatch(setSnackbar({ open: true, severity: 'error', message: i18next.t("__unexpected_error") }));

    } else {
      if (action?.payload?.data?.code === 401) {
        api.dispatch(removeAuth());
        api.dispatch(setSnackbar({ open: true, severity: 'error', message: i18next.t("__session_expired") }));

      } else {
        if (action?.payload?.data?.error_details) api.dispatch(setSnackbar({ open: true, severity: 'error', message: i18next.t("__form_error") }));
        else api.dispatch(setSnackbar({ open: true, severity: 'error', message: action?.payload?.data?.error?.message || i18next.t("__an_error_occurred") }));
      }
    }
  }

  return next(action);
}

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 60,
  tagTypes: ['dids', 'schedules', 'campaigns', 'domains', 'services', 'widgets', 'serviceInfo', 'info', 'prefix', 'tracks', 'supervision', 'provider'],
  endpoints: (builder) => ({
    // Login
    login: builder.mutation({
      query: (payload) => ({ url: `${process.env.REACT_APP_API_URL}/login`, method: 'POST', body: payload }),
    }),
    loginForgot: builder.mutation({
      query: (payload) => ({ url: `${process.env.REACT_APP_API_URL}/login/recover`, method: 'POST', body: payload }),
    }),
    loginRecover: builder.mutation({
      query: ({ token, payload }) => ({ url: `${process.env.REACT_APP_API_URL}/login/${token}`, method: 'POST', body: payload }),
    }),

    // User
    userCurrent: builder.query({
      query: () => ({ url: `${process.env.REACT_APP_API_URL}/users/current`, }),
    }),
    userUpdate: builder.mutation({
      query: (payload) => ({ url: `${process.env.REACT_APP_API_URL}/users`, method: 'PATCH', body: payload }),
    }),

    // DID
    didList: builder.query({
      query: () => ({ url: `${process.env.REACT_APP_API_URL}/did`, }),
      providesTags: (result) => providesList(result?.data, 'dids', 'uuid'),
    }),
    didDetail: builder.query({
      query: (didId) => ({ url: `${process.env.REACT_APP_API_URL}/did/${didId}` }),
      providesTags: (result, error, didId) => [{ type: 'dids', id: didId }],
    }),
    didCreate: builder.mutation({
      query: (payload) => ({ url: `${process.env.REACT_APP_API_URL}/did`, method: 'POST', body: payload }),
      invalidatesTags: [{ type: 'dids', id: 'LIST' }],
    }),
    didUpdate: builder.mutation({
      query: ({ uuid, ...payload }) => ({ url: `${process.env.REACT_APP_API_URL}/did/${uuid}`, method: 'PATCH', body: payload }),
      invalidatesTags: [{ type: 'dids', id: 'LIST' }],
    }),
    didDelete: builder.mutation({
      query: (didId) => ({ url: `${process.env.REACT_APP_API_URL}/did/${didId}`, method: 'DELETE' }),
      invalidatesTags: [{ type: 'dids', id: 'LIST' }],
    }),
    didDeleteAll: builder.mutation({
      query: (payload) => ({ url: `${process.env.REACT_APP_API_URL}/did`, method: 'DELETE', body: payload }),
      invalidatesTags: [{ type: 'dids', id: 'LIST' }],
    }),
    didInfo: builder.query({
      query: () => ({ url: `${process.env.REACT_APP_API_URL}/did/info`, }),
      providesTags: (result) => providesList(result?.data, 'dids', 'uuid'),
    }),
    didOtpSend: builder.mutation({
      query: ({ didId, payload }) => ({ url: `${process.env.REACT_APP_API_URL}/did/${didId}/otp/send`, method: 'POST', body: payload }),
    }),
    didOtpVerify: builder.mutation({
      query: ({ didId, payload }) => ({ url: `${process.env.REACT_APP_API_URL}/did/${didId}/otp/verify`, method: 'POST', body: payload }),
      invalidatesTags: [{ type: 'dids', id: 'LIST' }],
    }),

    // Schedule
    scheduleList: builder.query({
      query: () => ({ url: `${process.env.REACT_APP_API_URL}/schedule`, }),
      providesTags: (result) => providesList(result?.data, 'schedules', 'uuid'),
    }),
    scheduleDetail: builder.query({
      query: (scheduleId) => ({ url: `${process.env.REACT_APP_API_URL}/schedule/${scheduleId}` }),
      providesTags: (result, error, scheduleId) => [{ type: 'schedules', id: scheduleId }],
    }),
    scheduleCreate: builder.mutation({
      query: (payload) => ({ url: `${process.env.REACT_APP_API_URL}/schedule`, method: 'POST', body: payload }),
      invalidatesTags: [{ type: 'schedules', id: 'LIST' }],
    }),
    scheduleUpdate: builder.mutation({
      query: ({ uuid, ...payload }) => ({ url: `${process.env.REACT_APP_API_URL}/schedule/${uuid}`, method: 'PATCH', body: payload }),
      invalidatesTags: [{ type: 'schedules', id: 'LIST' }],
    }),
    scheduleDelete: builder.mutation({
      query: (scheduleId) => ({ url: `${process.env.REACT_APP_API_URL}/schedule/${scheduleId}`, method: 'DELETE' }),
      invalidatesTags: [{ type: 'schedules', id: 'LIST' }],
    }),
    scheduleDeleteAll: builder.mutation({
      query: (payload) => ({ url: `${process.env.REACT_APP_API_URL}/schedule`, method: 'DELETE', body: payload }),
      invalidatesTags: [{ type: 'schedules', id: 'LIST' }],
    }),
    scheduleInfo: builder.query({
      query: () => ({ url: `${process.env.REACT_APP_API_URL}/schedule/info`, }),
      providesTags: (result) => providesList(result?.data, 'schedules', 'uuid'),
    }),

    // Campaign
    campaignList: builder.query({
      query: () => ({ url: `${process.env.REACT_APP_API_URL}/campaign`, }),
      providesTags: (result) => providesList(result?.data, 'campaigns', 'uuid'),
    }),
    campaignDetail: builder.query({
      query: (campaignId) => ({ url: `${process.env.REACT_APP_API_URL}/campaign/${campaignId}` }),
      providesTags: (result, error, campaignId) => [{ type: 'campaigns', id: campaignId }],
    }),
    campaignCreate: builder.mutation({
      query: (payload) => ({ url: `${process.env.REACT_APP_API_URL}/campaign`, method: 'POST', body: payload }),
      invalidatesTags: [{ type: 'campaigns', id: 'LIST' }, { type: 'info' }],
    }),
    campaignUpdate: builder.mutation({
      query: ({ uuid, ...payload }) => ({ url: `${process.env.REACT_APP_API_URL}/campaign/${uuid}`, method: 'PATCH', body: payload }),
      invalidatesTags: [{ type: 'campaigns', id: 'LIST' }, { type: 'info' }],
    }),
    campaignDelete: builder.mutation({
      query: (campaignId) => ({ url: `${process.env.REACT_APP_API_URL}/campaign/${campaignId}`, method: 'DELETE' }),
      invalidatesTags: [{ type: 'campaigns', id: 'LIST' }, { type: 'info' }],
    }),
    campaignDeleteAll: builder.mutation({
      query: (payload) => ({ url: `${process.env.REACT_APP_API_URL}/campaign`, method: 'DELETE', body: payload }),
      invalidatesTags: [{ type: 'campaigns', id: 'LIST' }],
    }),
    campaignStatus: builder.query({
      query: () => ({ url: `${process.env.REACT_APP_API_URL}/campaign_status`, }),
    }),

    // Domain
    domainList: builder.query({
      query: () => ({ url: `${process.env.REACT_APP_API_URL}/domain`, }),
      providesTags: (result) => providesList(result?.data, 'domains', 'uuid'),
    }),

    // Service
    serviceList: builder.query({
      query: () => ({ url: `${process.env.REACT_APP_API_URL}/service`, }),
      providesTags: (result) => providesList(result?.data, 'services', 'uuid'),
    }),
    serviceInfo: builder.query({
      query: () => ({ url: `${process.env.REACT_APP_API_URL}/service/info`, }),
      providesTags: (result) => providesList(result?.data, 'services', 'uuid'),
    }),
    serviceDetail: builder.query({
      query: (serviceId) => ({ url: `${process.env.REACT_APP_API_URL}/service/${serviceId}` }),
      providesTags: (result, error, serviceId) => [{ type: 'services', id: serviceId }],
    }),
    serviceCreate: builder.mutation({
      query: (payload) => ({ url: `${process.env.REACT_APP_API_URL}/service`, method: 'POST', body: payload }),
      invalidatesTags: [{ type: 'services', id: 'LIST' }, { type: 'info' }],
    }),
    serviceUpdate: builder.mutation({
      query: ({ uuid, ...payload }) => ({ url: `${process.env.REACT_APP_API_URL}/service/${uuid}`, method: 'PATCH', body: payload }),
      invalidatesTags: [{ type: 'services', id: 'LIST' }, { type: 'info' }],
    }),
    serviceDelete: builder.mutation({
      query: (serviceId) => ({ url: `${process.env.REACT_APP_API_URL}/service/${serviceId}`, method: 'DELETE' }),
      invalidatesTags: [{ type: 'services', id: 'LIST' }, { type: 'info' }],
    }),
    serviceDeleteQuantity: builder.mutation({
      query: (payload) => ({ url: `${process.env.REACT_APP_API_URL}/service`, method: 'DELETE', body: payload }),
      invalidatesTags: [{ type: 'services', id: 'LIST' }, { type: 'info' }],
    }),

    // Widgets
    widgetList: builder.query({
      query: () => ({ url: `${process.env.REACT_APP_API_URL}/widget`, }),
      providesTags: (result) => providesList(result?.data, 'widgets', 'uuid'),
    }),
    widgetDetail: builder.query({
      query: (widgetId) => ({ url: `${process.env.REACT_APP_API_URL}/widget/${widgetId}` }),
      providesTags: (result, error, widgetId) => [{ type: 'widgets', id: widgetId }],
    }),
    widgetCreate: builder.mutation({
      query: (payload) => ({ url: `${process.env.REACT_APP_API_URL}/widget`, method: 'POST', body: payload }),
      invalidatesTags: [{ type: 'widgets', id: 'LIST' }],
    }),
    widgetUpdate: builder.mutation({
      query: ({ uuid, ...payload }) => ({ url: `${process.env.REACT_APP_API_URL}/widget/${uuid}`, method: 'PATCH', body: payload }),
      invalidatesTags: [{ type: 'widgets', id: 'LIST' }],
    }),
    widgetDelete: builder.mutation({
      query: (widgetId) => ({ url: `${process.env.REACT_APP_API_URL}/widget/${widgetId}`, method: 'DELETE' }),
      invalidatesTags: [{ type: 'widgets', id: 'LIST' }],
    }),
    widgetDeleteAll: builder.mutation({
      query: (payload) => ({ url: `${process.env.REACT_APP_API_URL}/widget`, method: 'DELETE', body: payload }),
      invalidatesTags: [{ type: 'widgets', id: 'LIST' }],
    }),
    widgetInfo: builder.query({
      query: () => ({ url: `${process.env.REACT_APP_API_URL}/widget/info`, }),
      providesTags: (result) => providesList(result?.data, 'widgets', 'uuid'),
    }),

    // Tracks
    trackList: builder.query({
      query: ({ page, filter }) => ({ url: `${process.env.REACT_APP_API_URL}/tracks?page=${page}&filter=${filter}` }),
      providesTags: (result) => providesList(result?.data, 'tracks', 'uuid'),

    }),
    trackStatusList: builder.query({
      query: () => ({ url: `${process.env.REACT_APP_API_URL}/track/status`, }),
      providesTags: (result) => ['tracks'],
    }),
    trackDetail: builder.query({
      query: (trackId) => ({ url: `${process.env.REACT_APP_API_URL}/track/${trackId}` }),
      providesTags: (result, error, trackId) => [{ type: 'tracks', id: trackId }],
    }),

    // Info
    campaignServicesInfo: builder.query({
      query: () => ({ url: `${process.env.REACT_APP_API_URL}/info`, }),
      providesTags: (result) => ['info'],
    }),

    // Prefix
    countryPrefixList: builder.query({
      query: () => ({ url: `${process.env.REACT_APP_API_URL}/prefix`, }),
      providesTags: (result) => ['prefix'],
    }),

    // Provider
    providerList: builder.query({
      query: () => ({ url: `${process.env.REACT_APP_API_URL}/providers`, }),
      providesTags: (result) => ['provider'],
    }),

    // Supervision
    supervision: builder.query({
      query: () => ({ url: `${process.env.REACT_APP_API_URL}/supervision`, }),
      providesTags: (result) => ['supervision'],
    }),

    // Statistics
    getReport: builder.query({
      query: ({ clientId, reportId, filter }) => ({ url: `${process.env.REACT_APP_ANALYTICS_URL}/report/${clientId}/${reportId}?filter=${filter}`, }),
    }),
  }),
});

function providesList(resultsWithIds, tagType, idProp) {
  return resultsWithIds?.data ?
    [
      { type: tagType, id: 'LIST' },
      ...resultsWithIds?.data.map(({ [idProp]: id }) => ({ type: tagType, id }))
    ]
    :
    [{ type: tagType, id: 'LIST' }]
}

export const {
  // Login
  useLoginMutation, useLoginForgotMutation, useLoginRecoverMutation,

  // User
  useLazyUserCurrentQuery, useUserUpdateMutation,

  // DID
  useDidListQuery, useDidDetailQuery, useDidCreateMutation, useDidUpdateMutation, useDidDeleteMutation, useDidDeleteAllMutation, useDidInfoQuery, useDidOtpSendMutation, useDidOtpVerifyMutation,

  // Schedule
  useScheduleListQuery, useScheduleDetailQuery, useScheduleCreateMutation, useScheduleUpdateMutation, useScheduleDeleteMutation, useScheduleDeleteAllMutation, useScheduleInfoQuery,

  // Widgets
  useWidgetListQuery, useWidgetDetailQuery, useWidgetCreateMutation, useWidgetUpdateMutation, useWidgetDeleteMutation, useWidgetDeleteAllMutation, useWidgetInfoQuery,

  // Campaign
  useCampaignListQuery, useCampaignDetailQuery, useCampaignCreateMutation, useCampaignUpdateMutation, useCampaignDeleteMutation, useCampaignDeleteAllMutation, useCampaignStatusQuery,

  // Domain
  useDomainListQuery,

  // Service
  useServiceListQuery, useServiceInfoQuery, useServiceDetailQuery, useServiceCreateMutation, useServiceUpdateMutation, useServiceDeleteMutation, useServiceDeleteQuantityMutation,

  // Tracks
  useLazyTrackListQuery, useTrackStatusListQuery, useTrackDetailQuery,

  // Info
  useCampaignServicesInfoQuery,

  // Prefix
  useCountryPrefixListQuery,

  // Prefix
  useProviderListQuery,

  // Supervision
  useLazySupervisionQuery,

  // Report
  useLazyGetReportQuery
} = api;