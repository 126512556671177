import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useWidgetCreateMutation } from 'services/api';
// import WidgetForm from '../widgetForm/WidgetForm';
import WidgetForm from '../widgetForm/WidgetForm';
import * as Yup from 'yup';


function WidgetCreate(props) {
  const { t } = useTranslation();
  const { values, open, setOpen } = props;
  const [widgetCreate, { isLoading, error }] = useWidgetCreateMutation();

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      config: {
        colors: {
          main: "#FF9900",
          success: "#02d20c",
          error: "#F00000"
        },
        callStatus: {
          form: {
            show: false,
            media: "https://placehold.co/300x170.png",
            title: "Formulario",
            subtitle: "Introduce tus datos",
            fields: ['NAME'],
            button_send: "Continuar"
          },
          standBy: {
            media: "https://placehold.co/300x170.png",
            title: "Pulse para solicitar una llamada",
            subtitle: "Recuerde que debe aceptar el permiso al micrófono del navegador",
            button_request_call: "Solicitar llamada"
          },
          ringing: {
            media: "https://placehold.co/300x170.png",
            title: "Llamada entrante",
            subtitle: "Acepte la llamada si desea hablar con un agente comercial",
            button_pick_up: "",
            button_hang_up: ""
          },
          inProgress: {
            media: "https://placehold.co/300x170.png",
            title: "En conversación...",
            subtitle: "Está siendo atendido por un agente comercial de Walmeric",
            button_hang_up: ""
          },
          completed: {
            media: "https://placehold.co/300x170.png",
            title: "Satisfacción",
            subtitle: "Valore la atención recibida",
            button_send: "Enviar"
          }
        }
      }
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      description: Yup.string().required(),
      config: Yup.object({
        colors: Yup.object({
          main: Yup.string().required(),
          success: Yup.string().required(),
          error: Yup.string().required(),
        }),
        callStatus: Yup.object({
          form: Yup.object({
            show: Yup.boolean(),
            media: Yup.string().required(),
            title: Yup.string().required(),
            subtitle: Yup.string().required(),
            fields: Yup.array(),
            button_send: Yup.string().required(),
          }),
          standBy: Yup.object({
            media: Yup.string().required(),
            title: Yup.string().required(),
            subtitle: Yup.string().required(),
            button_request_call: Yup.string().required(),
          }),
          ringing: Yup.object({
            media: Yup.string().required(),
            title: Yup.string().required(),
            subtitle: Yup.string().required(),
            button_pick_up: Yup.string(),
            button_hang_up: Yup.string(),
          }),
          inProgress: Yup.object({
            media: Yup.string().required(),
            title: Yup.string().required(),
            subtitle: Yup.string().required(),
            button_hang_up: Yup.string(),
          }),
          completed: Yup.object({
            media: Yup.string().required(),
            title: Yup.string().required(),
            subtitle: Yup.string().required(),
            button_send: Yup.string().required(),
          }),
        }),
      }),
    }),
    onSubmit: (values) => {
      const payload = { ...values };
      delete payload.assigned;
      delete payload.uuid;
      widgetCreate(payload).unwrap().then(() => {
        close();
      }).catch(() => { });
    },
  });

  useEffect(() => {
    if (open && Boolean(Object.keys(values).length)) formik.setValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, open]);

  function close() {
    setOpen(false);
    formik.resetForm();
  }

  return (
    <Dialog open={open} onClose={() => !isLoading && close()} fullWidth maxWidth="xl">
      {isLoading && <LinearProgress />}
      <DialogTitle>{t("__widget_create_title")}</DialogTitle>
      <DialogContent>
        <WidgetForm formik={formik} error={error?.data} />
      </DialogContent>
      <DialogActions sx={{ pr: 3, pl: 3, pb: 3 }}>
        <Button disabled={isLoading} onClick={() => close()}>{t("__cancel")}</Button>
        <Button variant="outlined" disabled={isLoading} onClick={formik.handleSubmit}>{t("__accept")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default WidgetCreate;