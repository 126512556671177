import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, LinearProgress, DialogContent, DialogContentText, } from '@mui/material';
import { useServiceDeleteMutation } from 'services/api';
import { useTranslation } from 'react-i18next';


function ServiceDelete(props) {
  const { t } = useTranslation();
  const { values, open, setOpen } = props;
  const [serviceDelete, { isFetching: isServiceDeleting }] = useServiceDeleteMutation();

  return (
    <Dialog open={open} onClose={() => !isServiceDeleting && setOpen(false)} fullWidth maxWidth="md">
      {isServiceDeleting && <LinearProgress />}
      <DialogTitle>{t("__service_delete_title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {values.uuid}
        </DialogContentText>
        <DialogContentText>
          {values.status?.name}
        </DialogContentText>
        <DialogContentText>
          {values.status?.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isServiceDeleting} onClick={() => setOpen(false)}>{t("__cancel")}</Button>
        <Button color="error" variant="outlined" disabled={isServiceDeleting} onClick={() => serviceDelete(values.uuid).unwrap().then(() => setOpen(false)).catch(() => { })}>{t("__delete")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ServiceDelete;