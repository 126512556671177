import { configureStore } from '@reduxjs/toolkit';
import { api, logger } from 'services/api';
import appReducer from 'slices/app.slice';
import authReducer from 'slices/auth.slice';
import statisticsReducer from 'slices/statistics.slice';
import supervisionReducer from 'slices/supervision.slice';
import trackReducer from 'slices/track.slice';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    app: appReducer,
    auth: authReducer,
    statistics: statisticsReducer,
    supervision: supervisionReducer,
    track: trackReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }).concat(api.middleware).concat(logger),
});
