import React, { useState } from 'react';
import { Grid, Box, Button, IconButton, Paper, LinearProgress, Tooltip, Avatar, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridActionsCellItem, esES, GridToolbarContainer, GridSelectedRowCount, GridPagination, GridFooterContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useCampaignListQuery, useCampaignServicesInfoQuery } from 'services/api';
import { common, blue, green, red } from "@mui/material/colors";
import CampaignCreate from './campaignCreate/CampaignCreate';
import CampaignUpdate from './campaignUpdate/CampaignUpdate';
import CampaignDelete from './campaignDelete/CampaignDelete';
import CampaignScript from './campaignScript/CampaignScript';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import i18n from 'services/i18n';


export function Campaign() {
  const { t } = useTranslation();
  const { data: campaignList, isFetching: isCampaignListFetching } = useCampaignListQuery();
  const { data: campaignServicesInfo, isFetching: isCampaignServicesInfoFetching } = useCampaignServicesInfoQuery();
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [openCreate, setOpenCreate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openScript, setOpenScript] = useState(false);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'end' }}>
          <Button size='small' startIcon={<AddOutlinedIcon />} onClick={() => setOpenCreate(true)}>
            {t('__add')}
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }

  function CustomFooter() {
    return (
      <GridFooterContainer sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        {Boolean(selectedRowIds.length) ?
          <Box sx={{ display: 'flex' }}>
            <Tooltip title={t('__delete')}>
              <IconButton
                color="error"
                aria-label="open drawer"
                onClick={() => setOpenDelete(true)}
                sx={{ ml: 0.7 }}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
            <GridSelectedRowCount selectedRowCount={selectedRowIds.length} />
          </Box>
          :
          <Box />
        }
        <GridPagination sx={{ flex: 1 }} />
      </GridFooterContainer>
    );
  }

  const columns = [
    {
      field: 'uuid',
      headerName: t("UUID"),
      flex: 1
    },
    {
      field: 'name',
      headerName: t("__campaign_form_name"),
      flex: 1
    },
    {
      field: 'status',
      headerName: t("__campaign_form_status"),
      renderCell: (params) => {
        return <Typography variant='body2' color={(params.row.status.id === 2) ? 'error.main' : 'success.main'}>{t(params.row.status.name_t)}</Typography>
      },
      flex: 1
    },
    {
      field: 'description',
      headerName: t("__campaign_form_description"),
      flex: 1
    },
    {
      field: 'domain',
      headerName: t("__campaign_form_domain"),
      renderCell: (params) => (
        params.row.domain.name
      ),
      flex: 1
    },
    {
      field: 'did',
      headerName: t("__campaign_form_did"),
      renderCell: (params) => (
        params.row.did.name
      ),
      flex: 1
    },
    {
      field: 'schedule',
      headerName: t("__campaign_form_schedule"),
      renderCell: (params) => (
        params.row.schedule.name
      ),
      flex: 1
    },
    {
      field: 'widget',
      headerName: t("__campaign_form_widget"),
      renderCell: (params) => (
        params.row.widget.name
      ),
      flex: 1
    },
    {
      field: 'services',
      headerName: t("__campaign_form_services"),
      renderCell: (params) => {
        return <Typography variant='body2' color={!params.row.services && 'error'}>{params.row.services}</Typography>
      },
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t("__actions"),
      renderHeader: () => (''),
      // width: 50,
      // maxWidth: 50,
      flex: 1,
      align: 'right',
      getActions: (params) => [
        <Tooltip title={t('__script')}>
          <GridActionsCellItem
            icon={<CodeOutlinedIcon />}
            label={t('__script')}
            onClick={() => {
              setSelectedRow(params.row);
              setTimeout(() => { setOpenScript(true); }, 0);
            }}
          />
        </Tooltip>,
        <Tooltip title={t('__edit')}>
          <GridActionsCellItem
            icon={<EditOutlinedIcon />}
            label={t('__edit')}
            onClick={() => {
              setSelectedRow(params.row);
              setTimeout(() => { setOpenUpdate(true); }, 0);
            }}
          />
        </Tooltip>,
        <Tooltip title={t('__delete')}>
          <GridActionsCellItem
            icon={<DeleteOutlinedIcon />}
            label={t('__delete')}
            color='error'
            onClick={() => {
              setSelectedRow(params.row);
              setTimeout(() => { setOpenDelete(true); }, 0);
            }}
          />
        </Tooltip>,
      ],
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    uuid: false,
    description: false,
    domain: false
  });

  return (
    <Grid container spacing={3}>

      <Grid item xs={12} sm={12} md={4}>
        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          {isCampaignServicesInfoFetching && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Avatar sx={{ bgcolor: blue[700], color: common.white }} variant="rounded">
              <CampaignOutlinedIcon />
            </Avatar>
            <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', ml: 2, fontWeight: 'bold', /* color: 'dimgray' */ }}>
              {isCampaignServicesInfoFetching ?
                <CircularProgress size={24} />
                :
                campaignServicesInfo?.data?.campaigns_active
              }
            </Typography>
          </Box>
          <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__active_campaigns')}</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          {isCampaignServicesInfoFetching && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Avatar sx={{ bgcolor: green[700], color: common.white }} variant="rounded">
              <ShoppingCartCheckoutIcon />
            </Avatar>
            <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', ml: 2, fontWeight: 'bold', /* color: 'dimgray' */ }}>
              {isCampaignServicesInfoFetching ?
                <CircularProgress size={24} />
                :
                campaignServicesInfo?.data?.services_assigned
              }
            </Typography>
          </Box>
          <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__assigned_services')}</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          {isCampaignServicesInfoFetching && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Avatar sx={{ bgcolor: red[700], color: common.white }} variant="rounded">
              <ProductionQuantityLimitsOutlinedIcon />
            </Avatar>
            <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', ml: 2, fontWeight: 'bold', /* color: 'dimgray' */ }}>
              {isCampaignServicesInfoFetching ?
                <CircularProgress size={24} />
                :
                campaignServicesInfo?.data?.services_unassigned
              }
            </Typography>
          </Box>
          <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__unassigned_services')}</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        {/* <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}> */}
        {/* <Typography variant="h6">Listado de Campaign's</Typography> */}
        {/* <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography> */}
        <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
          <DataGrid
            rows={campaignList?.data?.campaigns || []}
            getRowId={row => row.uuid}
            columns={columns}
            loading={isCampaignListFetching}
            autoHeight
            rowSelection
            checkboxSelection
            onRowSelectionModelChange={(params) => setSelectedRowIds(params)}
            localeText={i18n.language === 'es' ? esES.components.MuiDataGrid.defaultProps.localeText : {}}
            slots={{
              toolbar: CustomToolbar,
              footer: CustomFooter,
              loadingOverlay: LinearProgress
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              }
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(model) =>
              setColumnVisibilityModel(model)
            }
          />
        </Paper>
        {/* </Paper> */}
      </Grid>
      <CampaignCreate open={openCreate} setOpen={setOpenCreate} />
      <CampaignUpdate values={selectedRow} setValues={setSelectedRow} open={openUpdate} setOpen={setOpenUpdate} />
      <CampaignDelete values={selectedRow} setValues={setSelectedRow} valuesIds={selectedRowIds} open={openDelete} setOpen={setOpenDelete} />
      <CampaignScript values={selectedRow} open={openScript} setOpen={setOpenScript} />
    </Grid>
  );
}

export default Campaign;

