import React, { Fragment } from 'react';
import { TextField, Link, FormControl, InputLabel, Select, MenuItem, FormHelperText, Box, Grid, Tooltip, IconButton, Typography } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { daysOfWeek } from 'utils/mock';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

function ScheduleForm(props) {
  const { t } = useTranslation();
  const { formik, error } = props;

  const formDataBase = {
    dayType: '',
    timeFrom: new Date(null),
    timeTo: new Date(null),
  }

  const formDateBase = {
    scheduleDate: null,
    timeFrom: new Date(null),
    timeTo: new Date(null),
  }

  const handleAddScheduleData = () => formik.setFieldValue('scheduleData', [...formik.values.scheduleData, formDataBase]);
  const handleCloneScheduleData = (scheduleData) => formik.setFieldValue('scheduleData', [...formik.values.scheduleData, scheduleData]);
  const handleDeleteScheduleData = (index) => formik.setFieldValue('scheduleData', formik.values.scheduleData.filter((option, _index) => _index !== index));
  const handleAddScheduleDate = () => formik.setFieldValue('scheduleDate', [...formik.values.scheduleDate, formDateBase]);
  const handleCloneScheduleDate = (scheduleDate) => formik.setFieldValue('scheduleDate', [...formik.values.scheduleDate, scheduleDate]);
  const handleDeleteScheduleDate = (index) => formik.setFieldValue('scheduleDate', formik.values.scheduleDate.filter((option, _index) => _index !== index));

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      noValidate
      sx={{ mt: 1 }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="name"
            label={t("__schedule_form_name")}
            fullWidth
            autoFocus
            {...formik.getFieldProps('name')}
            error={Boolean(formik.submitCount) && (Boolean(formik.errors.name) || Boolean(error?.error_details?.name))}
            helperText={Boolean(formik.submitCount) && (formik.errors.name || error?.error_details?.name)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="description"
            label={t("__schedule_form_description")}
            fullWidth
            minRows={2}
            multiline
            {...formik.getFieldProps('description')}
            error={(Boolean(formik.submitCount) && Boolean(formik.errors.description)) || Boolean(error?.error_details?.description)}
            helperText={Boolean(formik.submitCount) && (formik.errors.description || error?.error_details?.description)}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body1'>{t('__schedule_form_days_type')}</Typography>
        </Grid>

        {/* DATA */}
        {formik.values.scheduleData.map((scheduleData, i) =>
          <Fragment key={i}>
            <Grid item xs={12} sm={4}>
              <FormControl sx={{ width: '100%' }} error={Boolean(formik.submitCount) && (Boolean(formik.errors?.scheduleData?.[i]?.dayType) || Boolean(error?.error_details?.scheduleData?.[i]?.dayType))}>
                <InputLabel id="day-type-label">{t('__schedule_form_day_type')}</InputLabel>
                <Select
                  id="day-type"
                  required
                  labelId="day-type-label"
                  label={t('__schedule_form_day_type')}
                  {...formik.getFieldProps(`scheduleData[${i}].dayType`)}
                >
                  {daysOfWeek.map((day) => (
                    <MenuItem key={day.id} value={day.id}>{t(day.name_t)}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{Boolean(formik.submitCount) && (formik.errors?.scheduleData?.[i]?.dayType || error?.error_details?.scheduleData?.[i]?.dayType)}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControl sx={{ width: '100%' }}>
                <TimePicker
                  label={t('__time_from')}
                  // ampm={false}
                  value={dayjs.utc(formik.values?.scheduleData?.[i]?.timeFrom)}
                  onChange={(value) => formik.setFieldValue(`scheduleData[${i}].timeFrom`, value)}
                  slotProps={{
                    textField: {
                      required: true,
                      error: Boolean(formik.submitCount) && (Boolean(formik.errors?.scheduleData?.[i]?.timeFrom) || Boolean(error?.error_details?.scheduleData?.[i]?.timeFrom)),
                      helperText: Boolean(formik.submitCount) && (formik.errors?.scheduleData?.[i]?.timeFrom || error?.error_details?.scheduleData?.[i]?.timeFrom),
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControl sx={{ width: '100%' }}>
                <TimePicker
                  label={t('__time_to')}
                  // ampm={false}
                  value={dayjs.utc(formik.values?.scheduleData?.[i]?.timeTo)}
                  onChange={(value) => formik.setFieldValue(`scheduleData[${i}].timeTo`, value)}
                  slotProps={{
                    textField: {
                      required: true,
                      error: Boolean(formik.submitCount) && (Boolean(formik.errors?.scheduleData?.[i]?.timeTo) || Boolean(error?.error_details?.scheduleData?.[i]?.timeTo)),
                      helperText: Boolean(formik.submitCount) && (formik.errors?.scheduleData?.[i]?.timeTo || error?.error_details?.scheduleData?.[i]?.timeTo),
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={2} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 0.8 }}>
              <Box>
                <Tooltip title={t('__clone')}>
                  <IconButton
                    onClick={() => handleCloneScheduleData(scheduleData)}
                  >
                    <ContentCopyOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box>
                <Tooltip title={t('__delete')}>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteScheduleData(i)}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Fragment>
        )}

        <Grid item xs={12}>
          <Link
            type="button"
            component="button"
            underline="none"
            variant="body2"
            onClick={() => handleAddScheduleData()}
          >
            {t('__schedule_form_add_data')}
          </Link>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body1'>{t('__schedule_form_specific_days')}</Typography>
        </Grid>

        {/* DATE */}
        {formik.values.scheduleDate.map((scheduleDate, i) =>
          <Fragment key={i}>
            <Grid item xs={12} sm={4}>
              <FormControl sx={{ width: '100%' }}>
                <DatePicker
                  disablePast
                  label={t('__schedule_form_specific_day')}
                  value={dayjs(formik.values?.scheduleDate?.[i]?.scheduleDate)}
                  onChange={(value) => formik.setFieldValue(`scheduleDate[${i}].scheduleDate`, dayjs(value))}
                  slotProps={{
                    textField: {
                      required: true,
                      error: Boolean(formik.submitCount) && (Boolean(formik.errors?.scheduleDate?.[i]?.scheduleDate) || Boolean(error?.error_details?.scheduleDate?.[i]?.scheduleDate)),
                      helperText: Boolean(formik.submitCount) && (formik.errors?.scheduleDate?.[i]?.scheduleDate || error?.error_details?.scheduleDate?.[i]?.scheduleDate),
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControl sx={{ width: '100%' }}>
                <TimePicker
                  label={t('__time_from')}
                  // ampm={false}
                  value={dayjs.utc(formik.values?.scheduleDate?.[i]?.timeFrom)}
                  onChange={(value) => formik.setFieldValue(`scheduleDate[${i}].timeFrom`, value)}
                  slotProps={{
                    textField: {
                      required: true,
                      error: Boolean(formik.submitCount) && (Boolean(formik.errors?.scheduleDate?.[i]?.timeFrom) || Boolean(error?.error_details?.scheduleDate?.[i]?.timeFrom)),
                      helperText: Boolean(formik.submitCount) && (formik.errors?.scheduleDate?.[i]?.timeFrom || error?.error_details?.scheduleDate?.[i]?.timeFrom),
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControl sx={{ width: '100%' }}>
                <TimePicker
                  label={t('__time_to')}
                  // ampm={false}
                  value={dayjs.utc(formik.values?.scheduleDate?.[i]?.timeTo)}
                  onChange={(value) => formik.setFieldValue(`scheduleDate[${i}].timeTo`, value)}
                  slotProps={{
                    textField: {
                      required: true,
                      error: Boolean(formik.submitCount) && (Boolean(formik.errors?.scheduleDate?.[i]?.timeTo) || Boolean(error?.error_details?.scheduleDate?.[i]?.timeTo)),
                      helperText: Boolean(formik.submitCount) && (formik.errors?.scheduleDate?.[i]?.timeTo || error?.error_details?.scheduleDate?.[i]?.timeTo),
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={2} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 0.8 }}>
              <Box>
                <Tooltip title={t('__clone')}>
                  <IconButton
                    onClick={() => handleCloneScheduleDate(scheduleDate)}
                  >
                    <ContentCopyOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box>
                <Tooltip title={t('__delete')}>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteScheduleDate(i)}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Fragment>
        )}

        <Grid item xs={12}>
          <Link
            type="button"
            component="button"
            underline="none"
            variant="body2"
            onClick={() => handleAddScheduleDate()}
          >
            {t('__schedule_form_add_date')}
          </Link>
        </Grid>
      </Grid>

      {/* <Typography component="div" sx={{ color: 'error.main' }}>{error?.error}</Typography> */}
    </Box>
  );
}

export default ScheduleForm;