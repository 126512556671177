import React, { useEffect } from 'react';
import { Grid, Box, Avatar, Typography, Paper, LinearProgress, CircularProgress, Tooltip, IconButton } from '@mui/material';
import { DataGrid, esES, GridToolbarContainer, GridPagination, GridFooterContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useLazyGetReportQuery, useLazySupervisionQuery, useTrackStatusListQuery, useCampaignListQuery } from 'services/api';
import { getUser } from 'services/localStorage';
import SupervisionFilter from './supervisionFilter/SupervisionFilter';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import AlarmOffOutlinedIcon from '@mui/icons-material/AlarmOffOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { common } from "@mui/material/colors";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'services/i18n';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as mTZ from 'moment-timezone';
import * as _ from 'lodash';
import darkTheme from 'highcharts/themes/dark-unica';
window.moment = mTZ; // Required by Highcharts

require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/highcharts-more")(Highcharts);
require("highcharts/highcharts-3d")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/offline-exporting")(Highcharts);
require("highcharts/modules/boost")(Highcharts);
require("highcharts/modules/funnel")(Highcharts);
require("highcharts/modules/timeline")(Highcharts);

export function Supervision() {
  const reportIds = ['20101', '20102', '20103'];
  const reports = useSelector((state) => state.statistics.reports);
  const filter = useSelector((state) => state.supervision.filter);
  const [showFilters, setShowFilters] = React.useState(false);
  const [reloadTimeout, setReloadTimeout] = React.useState(5);
  const { data: trackStatusList } = useTrackStatusListQuery();
  const { data: campaignList } = useCampaignListQuery();
  const [fetchSupervision, { data: supervisionData, isFetching: isSupervisionLoading }] = useLazySupervisionQuery();
  const [fetchReport] = useLazyGetReportQuery();
  const themeMode = useSelector((state) => state.app.theme);
  const { t } = useTranslation();

  useEffect(() => {
    if (themeMode === 'dark') darkTheme(Highcharts);
  }, [themeMode]);

  useEffect(() => {
    fetchAllData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    const interval = setInterval(() => setReloadTimeout(timer => timer - 1), 1000);
    if (reloadTimeout === 0) clearInterval(interval);
    return () => clearInterval(interval);
  }, [reloadTimeout]);

  function getReports() {
    if (!Object.values(reports).some(report => report.loading)) {
      setReloadTimeout(5);
      reportIds.forEach((reportId) => {
        fetchReport({ reportId: reportId, clientId: getUser()?.clients?.[0]?.uuid, filter: btoa(JSON.stringify(filter)) });
      });
    }
  }

  function fetchAllData() {
    fetchSupervision();
    getReports();
    fetchReport({ reportId: '20104', clientId: getUser()?.clients?.[0]?.uuid, filter: btoa(JSON.stringify(filter)) }); // Controls
    fetchReport({ reportId: '20105', clientId: getUser()?.clients?.[0]?.uuid, filter: btoa(JSON.stringify(filter)) }); // Tracks by Status
    fetchReport({ reportId: '20106', clientId: getUser()?.clients?.[0]?.uuid, filter: btoa(JSON.stringify(filter)) }); // Rating
  }

  function hasFilters() {
    let hasFilters = false;
    for (const [key, value] of Object.entries(filter)) {
      if ((key === 'campaign' || key === 'did' || key === 'widget' || key === 'trackStatus') && Boolean(value.length)) hasFilters = true;
    }
    return hasFilters;
  }

  function getStatusIcon(status) {
    switch (status) {
      case 'CONTROLS':
        return <AddIcCallOutlinedIcon />;
      case 'IMPRESSION':
        return <VisibilityOutlinedIcon />;
      case 'CLICK':
        return <AdsClickOutlinedIcon />;
      case 'BUSY':
        return <DoDisturbOnOutlinedIcon />;
      case 'OUT_OF_TIME':
        return <AlarmOffOutlinedIcon />;
      case 'IN_PROGRESS':
        return <UpdateOutlinedIcon />;
      case 'RANKING':
        return <EqualizerIcon />;
      case 'DONE':
        return <TaskAltOutlinedIcon />;
      case 'END':
        return <AssignmentTurnedInOutlinedIcon />;
      case 'IN_CALL':
        return <PhoneOutlinedIcon />;
      case 'ERROR':
        return <ErrorOutlineOutlinedIcon />;
      case 'RATING':
        return <StarOutlineIcon />;
      default:
        return '';
    }
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function CustomFooter() {
    return (
      <GridFooterContainer sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        <GridPagination sx={{ flex: 1 }} />
      </GridFooterContainer>
    );
  }

  const kpi = (data) => {
    return <Grid item xs={12} sm={12} md={4} xl={2}>
      <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
        {data?.loading && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Avatar sx={{ bgcolor: '#FF9900', color: common.white }} variant="rounded">
            {data?.icon ? data.icon : <InfoOutlinedIcon />}
          </Avatar>
          <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', ml: 2, fontWeight: 'bold', /* color: 'dimgray' */ }}>
            {data?.loading ?
              <CircularProgress size={24} />
              :
              <>
                {data?.SERIE_VALUE || 0}
                {Boolean(data?.SERIE_PERCENTAGE) && <Box sx={{ fontSize: 14, color: 'gray', ml: 1, fontWeight: 400 }}>{data?.SERIE_PERCENTAGE}%</Box>}
              </>
            }
          </Typography>
        </Box>
        <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t(data?.name_t || trackStatusList?.data?.status?.find(trackStatus => trackStatus?.id === Number(data?.SERIE_ID))?.name_t)}</Typography>
      </Paper>
    </Grid>
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', textAlign: 'right' }}>
          {/* <Button variant="text" onClick={() => setShowFilters(!showFilters)}><FilterAltOffOutlinedIcon sx={{ mr: 1 }} />Filtrar</Button> */}
          <IconButton
            size="large"
            onClick={(e) => fetchAllData()}
            disabled={Boolean(reloadTimeout)}
          >
            <Tooltip title={t('__reload')}>
              <CachedOutlinedIcon />
            </Tooltip>
          </IconButton>
          <IconButton
            size="large"
            onClick={(e) => setShowFilters(!showFilters)}
          // color={true ? 'inherit' : 'primary'}
          >
            <Tooltip title={t('__filter')}>
              {hasFilters() ? <FilterAltOutlinedIcon color='primary' /> : <FilterAltOffOutlinedIcon />}
            </Tooltip>
          </IconButton>
        </Grid>

        {/* KPI */}
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {kpi({ loading: reports['20104']?.[0] === 'loading', name_t: '__supervision_kpi_controls', ...reports?.['20104']?.[0], icon: getStatusIcon('CONTROLS') })} {/* CONTROLS */}
            {kpi({ loading: reports['20105']?.[0] === 'loading', SERIE_ID: "1", ...reports?.['20105']?.find(r => r.SERIE_ID === "1" || r.SERIE_ID === 1), icon: getStatusIcon('IMPRESSION') })} {/* IMPRESSION */}
            {kpi({ loading: reports['20105']?.[0] === 'loading', SERIE_ID: "2", ...reports?.['20105']?.find(r => r.SERIE_ID === "2" || r.SERIE_ID === 2), icon: getStatusIcon('CLICK') })} {/* CLICK */}
            {kpi({ loading: reports['20105']?.[0] === 'loading', SERIE_ID: "5", ...reports?.['20105']?.find(r => r.SERIE_ID === "5" || r.SERIE_ID === 5), icon: getStatusIcon('IN_PROGRESS') })} {/* IN_PROGRESS */}
            {kpi({ loading: reports['20105']?.[0] === 'loading', SERIE_ID: "7", ...reports?.['20105']?.find(r => r.SERIE_ID === "7" || r.SERIE_ID === 7), icon: getStatusIcon('DONE') })} {/* DONE */}
            {kpi({ loading: reports['20105']?.[0] === 'loading', SERIE_ID: "6", ...reports?.['20105']?.find(r => r.SERIE_ID === "6" || r.SERIE_ID === 6), icon: getStatusIcon('RANKING') })} {/* RANKING */}
            {kpi({ loading: reports['20105']?.[0] === 'loading', SERIE_ID: "8", ...reports?.['20105']?.find(r => r.SERIE_ID === "8" || r.SERIE_ID === 8), icon: getStatusIcon('END') })} {/* END */}
            {kpi({ loading: reports['20105']?.[0] === 'loading', SERIE_ID: "3", ...reports?.['20105']?.find(r => r.SERIE_ID === "3" || r.SERIE_ID === 3), icon: getStatusIcon('BUSY') })} {/* BUSY */}
            {kpi({ loading: reports['20105']?.[0] === 'loading', SERIE_ID: "4", ...reports?.['20105']?.find(r => r.SERIE_ID === "4" || r.SERIE_ID === 4), icon: getStatusIcon('OUT_OF_TIME') })} {/* OUT_OF_TIME */}
            {kpi({ loading: reports['20105']?.[0] === 'loading', SERIE_ID: "99", ...reports?.['20105']?.find(r => r.SERIE_ID === "99" || r.SERIE_ID === 99), icon: getStatusIcon('ERROR') })} {/* ERROR */}
            {kpi({ loading: reports['20106']?.[0] === 'loading', name_t: '__supervision_kpi_rating', ...reports?.['20106']?.[0], icon: getStatusIcon('RATING') })} {/* RATING */}
          </Grid>
        </Grid>

        {Object.keys(reports).map((reportId) =>
          reportIds.includes(reportId) &&
          <Grid key={reportId} item xs={12} xl={4}>
            <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 0 }}>
              {reports[reportId]?.[0] === 'loading' && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
              {/* <Typography variant="h6">{reportId}</Typography> */}
              {/* <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__service_add_subtitle')}</Typography> */}
              {reports[reportId]?.[0] !== 'loading' &&
                <HighchartsReact key={reportId} highcharts={Highcharts} options={_.cloneDeep(reports[reportId])} />
              }
            </Paper>
          </Grid>
        )}

        <Grid item xs={12}>
          <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
            <Typography variant="h6" gutterBottom>{t('__supervision_kpi_controls')}</Typography>
            {/* <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography> */}
            <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
              <DataGrid
                rows={supervisionData?.data?.tables?.controls || []}
                getRowId={row => Math.random()}
                columns={[
                  {
                    field: 'campaign',
                    headerName: t("__supervision_table_campaign"),
                    renderCell: (params) => {
                      return campaignList?.data?.campaigns?.find(campaign => campaign.id === params.row.campaign)?.name
                    },
                    flex: 1
                  },
                  {
                    field: 'status',
                    headerName: t("__supervision_table_status"),
                    renderCell: (params) => {
                      return t(trackStatusList?.data?.status?.find(trackStatus => trackStatus.id === params.row.status)?.name_t)

                    },
                    flex: 1
                  },
                  {
                    field: 'quantity',
                    headerName: t("__supervision_table_quantity"),
                    flex: 1
                  },
                ]}
                loading={isSupervisionLoading}
                autoHeight
                rowSelection
                checkboxSelection
                localeText={i18n.language === 'es' ? esES.components.MuiDataGrid.defaultProps.localeText : {}}
                slots={{
                  toolbar: CustomToolbar,
                  footer: CustomFooter,
                  loadingOverlay: LinearProgress
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500, quickFilterFormatter: (awd) => console.log(awd) },
                  }
                }}
              />
            </Paper>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
            <Typography variant="h6" gutterBottom>Tracks</Typography>
            {/* <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography> */}
            <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
              <DataGrid
                rows={supervisionData?.data?.tables?.tracks || []}
                getRowId={row => Math.random()}
                columns={[
                  {
                    field: 'campaign',
                    headerName: t("__supervision_table_campaign"),
                    renderCell: (params) => {
                      return campaignList?.data?.campaigns?.find(campaign => campaign.id === params.row.campaign)?.name
                    },
                    flex: 1
                  },
                  {
                    field: 'status',
                    headerName: t("__supervision_table_status"),
                    renderCell: (params) => {
                      return t(trackStatusList?.data?.status?.find(trackStatus => trackStatus.id === params.row.status)?.name_t)

                    },
                    flex: 1
                  },
                  {
                    field: 'quantity',
                    headerName: t("__supervision_table_quantity"),
                    flex: 1
                  },
                ]}
                loading={isSupervisionLoading}
                autoHeight
                rowSelection
                checkboxSelection
                localeText={i18n.language === 'es' ? esES.components.MuiDataGrid.defaultProps.localeText : {}}
                slots={{
                  toolbar: CustomToolbar,
                  footer: CustomFooter,
                  loadingOverlay: LinearProgress
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  }
                }}
              />
            </Paper>
          </Paper>
        </Grid>

      </Grid>

      {/* FILTERS */}
      {showFilters &&
        <SupervisionFilter showFilters={showFilters} setShowFilters={setShowFilters} />
      }
    </>
  );
}

export default Supervision;
