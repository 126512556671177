import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { useCampaignCreateMutation } from 'services/api';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CampaignForm from '../campaignForm/CampaignForm';
import * as Yup from 'yup';


function DidCreate(props) {
  const { t } = useTranslation();
  const { open, setOpen } = props;
  const [campaignCreate, { isLoading, error }] = useCampaignCreateMutation();

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      status: '',
      domain: '',
      did: '',
      schedule: '',
      services: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      description: Yup.mixed().required(),
      status: Yup.object().required(),
      domain: Yup.object().required(),
      did: Yup.object().required(),
      schedule: Yup.object().required(),
      widget: Yup.object().required(),
      services: Yup.number().required(),
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
        domain: values.domain?.uuid,
        did: values.did?.uuid,
        schedule: values.schedule?.uuid,
        widget: values.widget?.uuid,
        status: values.status?.id,
      };
      campaignCreate(payload).unwrap().then(() => {
        close();
      }).catch(() => { });
    },
  });

  function close() {
    setOpen(false);
    formik.resetForm();
  }

  return (
    <Dialog open={open} onClose={() => !isLoading && close()} fullWidth maxWidth="md">
      {isLoading && <LinearProgress />}
      <DialogTitle>{t("__campaign_create_title")}</DialogTitle>
      <DialogContent>
        <CampaignForm formik={formik} error={error?.data} />
      </DialogContent>
      <DialogActions sx={{ pr: 3, pl: 3, pb: 3 }}>
        <Button disabled={isLoading} onClick={() => close()}>{t("__cancel")}</Button>
        <Button variant="outlined" disabled={isLoading} onClick={formik.handleSubmit}>{t("__accept")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DidCreate;