export function setToken(token) {
  if (typeof (Storage) !== 'undefined') {
    if (localStorage) localStorage.setItem("wphoneTok", token);
  } else {
    console.warn('Warning: localStorage is not available');
  }
}

export function getToken() {
  if (typeof (Storage) !== 'undefined') {
    if (localStorage) return localStorage.getItem("wphoneTok");
    return undefined;
  } else {
    console.warn('Warning: localStorage is not available');
  }
}

export function removeToken() {
  if (typeof (Storage) !== 'undefined') {
    if (localStorage) localStorage.removeItem("wphoneTok");
  } else {
    console.warn('Warning: localStorage is not available');
  }
}

export function setUser(user) {
  if (typeof (Storage) !== 'undefined') {
    if (localStorage) localStorage.setItem("wphoneUsr", JSON.stringify(user));
  } else {
    console.warn('Warning: localStorage is not available');
  }
}

export function getUser() {
  if (typeof (Storage) !== 'undefined') {
    if (localStorage) return JSON.parse(localStorage.getItem("wphoneUsr"));
    return undefined;
  } else {
    console.warn('Warning: localStorage is not available');
  }
}

export function removeUser() {
  if (typeof (Storage) !== 'undefined') {
    if (localStorage) localStorage.removeItem("wphoneUsr");
  } else {
    console.warn('Warning: localStorage is not available');
  }
}

export function setTheme(theme) {
  if (typeof (Storage) !== 'undefined') {
    if (localStorage) localStorage.setItem("wphoneTheme", theme);
  } else {
    console.warn('Warning: localStorage is not available');
  }
}

export function getTheme() {
  if (typeof (Storage) !== 'undefined') {
    if (localStorage) return localStorage.getItem("wphoneTheme");
    return undefined;
  } else {
    console.warn('Warning: localStorage is not available');
  }
}