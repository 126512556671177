import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialFilter = {
  date: {
    interval: 'today',
    dateFrom: dayjs().startOf('day'),
    dateTo: dayjs().endOf('day'),
  },
  campaign: [],
  did: [],
  widget: [],
  trackStatus: [],
  trackUuid: '',
  url: '',
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
};

const initialState = {
  initialFilter: initialFilter,
  filter: initialFilter
};

export const trackSlice = createSlice({
  name: 'track',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
});

export const { setFilter } = trackSlice.actions;

export const selectFilter = (state) => state.track.filter;
export const selectInitialFilter = (state) => state.track.initialFilter;

export default trackSlice.reducer;
