import React, { useEffect, useState } from 'react';
import { AppBar, Box, Menu, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography, Container, MenuItem, Avatar } from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined';
import PhoneForwardedOutlinedIcon from '@mui/icons-material/PhoneForwardedOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import BedtimeOutlinedIcon from '@mui/icons-material/BedtimeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined';
import { deepOrange } from '@mui/material/colors';
import { Outlet, useLocation, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTheme } from 'slices/app.slice';
import { removeAuth } from 'slices/auth.slice';
import i18n, { changeLanguage, languages } from 'services/i18n';
import logo from 'styles/images/logo/walmericlogo.svg';
import dayjs from 'dayjs';

const drawerWidth = 240;

export function Dashboard(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { window } = props;
  const themeMode = useSelector((state) => state.app.theme);
  const currentUser = useSelector((state) => state.auth.user);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openAccountMenu, setOpenAccountMenu] = useState(null);
  const [openLanguageSelector, setOpenLanguageSelector] = useState(null);
  const [time, setTime] = useState('');
  const menu = [
    {
      name: t('__menu_services'),
      path: 'services',
      icon: <AddIcCallOutlinedIcon />
    },
    {
      name: t('__menu_campaigns'),
      path: 'campaigns',
      icon: <CampaignOutlinedIcon />
    },
    {
      name: t('__menu_supervision_panel'),
      path: 'supervision',
      icon: <SupervisorAccountOutlinedIcon />
    },
    {
      name: t('__menu_did'),
      path: 'did',
      icon: <PhoneForwardedOutlinedIcon />
    },
    {
      name: t('__menu_schedule'),
      path: 'schedule',
      icon: <DateRangeOutlinedIcon />
    },
    {
      name: t('__menu_widget'),
      path: 'widget',
      icon: <WidgetsOutlinedIcon />
    },
    {
      name: t('__menu_track'),
      path: 'track',
      icon: <BubbleChartOutlinedIcon />
    },
    {
      name: t('__menu_analytics'),
      path: 'analytics',
      icon: <QueryStatsOutlinedIcon />
    },
  ]

  useEffect(() => {
    setTime(dayjs().format('ddd D MMM YYYY HH:mm:ss'));
    const interval = setInterval(() => {
      setTime(dayjs().format('ddd D MMM YYYY HH:mm:ss'));
    }, 1000);
    return () => clearTimeout(interval);
  }, [])

  const drawer = (
    <>
      <Box>
        {/* <Toolbar
      // sx={{ boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' }}
      /> */}
        <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
          {/* <IconButton onClick={toggleDrawer}>
          <ChevronLeft />
        </IconButton> */}
          {/* <img
            src={logo}
            alt={'logo'}
            loading="lazy"
            width={'140'}
          /> */}
        </Toolbar>
        <Divider />
        <List>
          {menu.map((item) => (
            <ListItem key={item.name} disablePadding>
              <ListItemButton component={Link} to={item.path} selected={location.pathname.includes(item.path)} onClick={() => setMobileOpen(false)}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
          {/* <ListItem sx={{ display: 'flex', 'justifyContent': 'center', 'alignSelf': 'flex-end' }}>
          by Walmeric
        </ListItem> */}
        </List>
        {/* <Divider />
      <List>
        <ListItem key={'Logout'} disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={'Logout'} />
          </ListItemButton>
        </ListItem>
      </List> */}
      </Box>
      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', mb: 2 }}>
        <img
          src={logo}
          alt={'logo'}
          loading="lazy"
          width={'140'}
        />
        <Typography variant="body2" color="grey" sx={{ pl: 4, pr: 4, pt: 3, textAlign: 'center' }}>Copyright © 2023 Walmeric Soluciones, S.L.</Typography>
      </Box>
    </>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          // background: 'linear-gradient(135deg, #ff9900 0%, #ffb74c 90%, #ffd699 100%)',
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            // edge="start"
            onClick={() => setMobileOpen(!mobileOpen)}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuOutlinedIcon />
          </IconButton>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
            {menu.find(item => location.pathname.includes(item.path))?.name}
          </Typography>

          {/* Time */}
          <Typography variant='body2' noWrap sx={{ alignItems: 'right', mr: 2, textTransform: 'capitalize', display: { xs: 'none', md: 'flex' } }} component="div">{time}</Typography>

          <Box sx={{ whiteSpace: 'nowrap' }}>
            {/* Language Selector */}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(e) => setOpenLanguageSelector(e.currentTarget)}
              color="inherit"
            >
              <img
                loading="lazy"
                width="20"
                src={languages().find(lang => lang.code === i18n.language)?.flag}
                alt={i18n.language}
              />
            </IconButton>
            <Menu
              anchorEl={openLanguageSelector}
              keepMounted
              open={Boolean(openLanguageSelector)}
              onClose={() => setOpenLanguageSelector(null)}
            >
              {languages().filter(lang => lang.code !== i18n.language).map(lang =>
                <MenuItem key={lang} onClick={() => {
                  setOpenLanguageSelector(null);
                  changeLanguage(lang.code);
                }}>
                  <img
                    loading="lazy"
                    width="20"
                    src={lang.flag}
                    alt={lang.code}
                  />
                </MenuItem>
              )}
            </Menu>

            {/* THEME */}
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              onClick={() => dispatch(setTheme(themeMode === 'light' ? 'dark' : 'light'))}
              color="inherit"
            >
              {themeMode === 'light' ? <LightModeOutlinedIcon /> : <BedtimeOutlinedIcon />}
            </IconButton>

            {/* Account */}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="accountMenu"
              aria-haspopup="true"
              onClick={(e) => setOpenAccountMenu(e.currentTarget)}
              color="inherit"
            >
              <PersonOutlineOutlinedIcon />
            </IconButton>
            <Menu
              id="accountMenu"
              anchorEl={openAccountMenu}
              keepMounted
              open={Boolean(openAccountMenu)}
              onClose={() => setOpenAccountMenu(null)}
            >
              <MenuItem style={{ backgroundColor: 'transparent', color: 'inherit', cursor: 'inherit' }} disableRipple>
                <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Avatar sx={{ bgcolor: deepOrange[500], mb: 1 }}>{currentUser?.name?.split(" ")?.map((n, i, a) => i === 0 || i + 1 === a?.length ? n?.[0] : null)?.join("")?.slice(0, 2)}</Avatar>
                  <Typography variant="body1">{currentUser?.name}</Typography>
                  <Typography variant="body2" sx={{ color: 'gray' }}>{currentUser?.email}</Typography>
                </Box>
              </MenuItem>
              <Divider />
              <MenuItem component={Link} to={'account'} onClick={() => setOpenAccountMenu(null)}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <ManageAccountsOutlinedIcon />
                  <Typography variant='body1' sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>{t('__menu_account')}</Typography>
                </Box>
              </MenuItem>
              <Divider />
              <MenuItem component={Link} to={'/'} onClick={() => { setOpenAccountMenu(null); dispatch(removeAuth()); }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <LogoutOutlinedIcon />
                  <Typography variant='body1' sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>{t('__menu_logout')}</Typography>
                </Box>
              </MenuItem>
              {/* <MenuItem sx={{ justifyContent: 'center' }} onClick={() => setOpenAccountMenu(null)}>{t('__menu_logout')}</MenuItem> */}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar >
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={() => setMobileOpen(!mobileOpen)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        // PaperProps={{
        //   sx: {
        //     backgroundColor: "#353535",
        //     color: '#fff'
        //   }
        // }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ mt: 2, mb: 2 }}>
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}

export default Dashboard;