import React, { useState } from 'react';
import { Grid, Box, TextField, Button, Avatar, FormControl, FormGroup, Typography, Paper, LinearProgress, CircularProgress, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridActionsCellItem, esES, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useCampaignListQuery, useCampaignServicesInfoQuery, useServiceDeleteQuantityMutation } from 'services/api';
import { common, blue, yellow, red, green } from "@mui/material/colors";
import { useFormik } from 'formik';
import { useServiceCreateMutation } from 'services/api';
import ServiceUpdate from './serviceUpdate/ServiceUpdate';
import ServiceDelete from './serviceDelete/ServiceDelete';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import i18n from 'services/i18n';
import * as Yup from 'yup';

export function Service() {
  const { t } = useTranslation();
  const { data: campaignList, isFetching: isCampaignListFetching } = useCampaignListQuery();
  const { data: campaignServicesInfo, isFetching: isCampaignServicesInfoFetching } = useCampaignServicesInfoQuery();
  const [serviceCreate, { isLoading: isServiceCreateLoading, error: errorAddService }] = useServiceCreateMutation();
  const [serviceDeleteQuantity, { isLoading: isServiceDeleteLoading, error: errorDeleteService }] = useServiceDeleteQuantityMutation();
  const [selectedRow, setSelectedRow] = useState({});
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({ uuid: false });

  const formikAddServices = useFormik({
    initialValues: {
      quantity: '',
    },
    validationSchema: Yup.object({
      quantity: Yup.number().required(),
    }),
    onSubmit: (values) => {
      const payload = { ...values };
      serviceCreate(payload);
      formikAddServices.resetForm();
    },
  });

  const formikDeleteServices = useFormik({
    initialValues: {
      quantity: '',
    },
    validationSchema: Yup.object({
      quantity: Yup.number().required(),
    }),
    onSubmit: (values) => {
      const payload = { ...values };
      serviceDeleteQuantity(payload);
      formikDeleteServices.resetForm();
    },
  });

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        {/* <Box sx={{ display: 'flex', flex: 1, justifyContent: 'end' }}>
          <Button size='small' startIcon={<AddOutlinedIcon />} onClick={() => setOpenCreate(true)}>
            {t('__add')}
          </Button>
        </Box> */}
      </GridToolbarContainer>
    );
  }

  const columns = [
    {
      field: 'uuid',
      headerName: t("UUID"),
      flex: 1
    },
    {
      field: 'name',
      headerName: t("__campaign_form_campaign"),
      flex: 1,
    },
    {
      field: 'services',
      headerName: t("__campaign_form_assigned_services"),
      flex: 1,
      renderCell: (params) => {
        return <Typography variant='body2' color={!params.row.services && 'error'}>{params.row.services}</Typography>
      },
    },
    {
      field: 'usage',
      headerName: t("__assigned_services_percentage"),
      flex: 1,
      renderCell: (params) => {
        const assigned_services_percentage = ((100 * params.row.services) / campaignServicesInfo?.data?.services_total).toFixed(2);
        return <Typography variant='body2' color={!assigned_services_percentage && 'error'}>{assigned_services_percentage}%</Typography>
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t("__actions"),
      renderHeader: () => (''),
      flex: 1,
      align: 'right',
      getActions: (params) => [
        <Tooltip title={t('__edit')}>
          <GridActionsCellItem
            icon={<EditOutlinedIcon />}
            label={t('__edit')}
            onClick={() => {
              setSelectedRow(params.row);
              setTimeout(() => { setOpenUpdate(true); }, 0);
            }}
          />
        </Tooltip>,
      ],
    },
  ];

  return (
    <Grid container spacing={3}>

      {/* INFO SERVICES */}
      <Grid item xs={12} sm={12} md={3}>
        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          {isCampaignServicesInfoFetching && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Avatar sx={{ bgcolor: blue[700], color: common.white }} variant="rounded">
              <AddShoppingCartOutlinedIcon />
            </Avatar>
            <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', ml: 2, fontWeight: 'bold', /* color: 'dimgray' */ }}>
              {isCampaignServicesInfoFetching ?
                <CircularProgress size={24} />
                :
                campaignServicesInfo?.data?.services_total
              }
            </Typography>
          </Box>
          <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__total_services')}</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={3}>
        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          {isCampaignServicesInfoFetching && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Avatar sx={{ bgcolor: green[700], color: common.white }} variant="rounded">
              <ShoppingCartCheckoutIcon />
            </Avatar>
            <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', ml: 2, fontWeight: 'bold', /* color: 'dimgray' */ }}>
              {isCampaignServicesInfoFetching ?
                <CircularProgress size={24} />
                :
                campaignServicesInfo?.data?.services_assigned
              }
            </Typography>
          </Box>
          <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__assigned_services')}</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={3}>
        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          {isCampaignServicesInfoFetching && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Avatar sx={{ bgcolor: red[700], color: common.white }} variant="rounded">
              <ProductionQuantityLimitsOutlinedIcon />
            </Avatar>
            <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', ml: 2, fontWeight: 'bold', /* color: 'dimgray' */ }}>
              {isCampaignServicesInfoFetching ?
                <CircularProgress size={24} />
                :
                campaignServicesInfo?.data?.services_unassigned
              }
            </Typography>
          </Box>
          <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__unassigned_services')}</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={3}>
        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          {isCampaignServicesInfoFetching && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Avatar sx={{ bgcolor: yellow[700], color: common.white }} variant="rounded">
              <ShoppingCartOutlinedIcon />
            </Avatar>
            <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', ml: 2, fontWeight: 'bold', /* color: 'dimgray' */ }}>
              {isCampaignServicesInfoFetching ?
                <CircularProgress size={24} />
                :
                campaignServicesInfo?.data?.services_max
              }
            </Typography>
          </Box>
          <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__max_services')}</Typography>
        </Paper>
      </Grid>

      {/* ADD SERVICES */}
      <Grid item xs={12} sm={12} md={6}>
        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          {isServiceCreateLoading && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
          {/* <Typography variant="h6">{t('__service_add_title')}</Typography> */}
          {/* <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__service_add_subtitle')}</Typography> */}
          <Box
            component="form"
            onSubmit={formikAddServices.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <FormControl margin="normal" fullWidth>
              <FormGroup>
                <TextField
                  id="servicesAddQuantity"
                  type='number'
                  label={t("__service_form_quantity")}
                  required
                  fullWidth
                  {...formikAddServices.getFieldProps('quantity')}
                  error={Boolean(formikAddServices.submitCount) && (Boolean(formikAddServices.errors.quantity) || Boolean(errorAddService?.error_details?.quantity))}
                  helperText={Boolean(formikAddServices.submitCount) && (formikAddServices.errors.quantity || errorAddService?.error_details?.quantity)}
                />
                <Box>
                  <Button type='submit' variant="contained" disabled={isServiceCreateLoading} sx={{ mt: 2 }}>
                    {isServiceCreateLoading ?
                      <CircularProgress size={24} sx={{ color: common.white }} />
                      :
                      t('__add')
                    }
                  </Button>
                </Box>
              </FormGroup>
            </FormControl>
          </Box>
        </Paper>
      </Grid>

      {/* REMOVE SERVICES */}
      <Grid item xs={12} sm={12} md={6}>
        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          {isServiceDeleteLoading && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
          {/* <Typography variant="h6">{t('__service_delete_title')}</Typography> */}
          {/* <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__service_delete_subtitle')}</Typography> */}
          <Box
            component="form"
            onSubmit={formikDeleteServices.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <FormControl margin="normal" fullWidth>
              <FormGroup>
                <TextField
                  id="servicesDeleteQuantity"
                  type='number'
                  label={t("__service_form_quantity_delete")}
                  required
                  fullWidth
                  {...formikDeleteServices.getFieldProps('quantity')}
                  error={Boolean(formikDeleteServices.submitCount) && (Boolean(formikDeleteServices.errors.quantity) || Boolean(errorDeleteService?.error_details?.quantity))}
                  helperText={Boolean(formikDeleteServices.submitCount) && (formikDeleteServices.errors.quantity || errorDeleteService?.error_details?.quantity)}
                />
                <Box>
                  <Button type='submit' color="error" variant="contained" disabled={isServiceDeleteLoading} sx={{ mt: 2 }}>
                    {isServiceDeleteLoading ?
                      <CircularProgress size={24} sx={{ color: common.white }} />
                      :
                      t('__delete')
                    }
                  </Button>
                </Box>
              </FormGroup>
            </FormControl>
          </Box>
        </Paper>
      </Grid>

      {/* TABLE */}
      <Grid item xs={12}>
        {/* <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}> */}
        {/* <Typography variant="h6">Listado de campañas</Typography> */}
        {/* <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography> */}

        <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
          <DataGrid
            rows={campaignList?.data?.campaigns || []}
            title='awd'
            getRowId={row => row.uuid}
            columns={columns}
            loading={isCampaignListFetching}
            autoHeight
            localeText={i18n.language === 'es' ? esES.components.MuiDataGrid.defaultProps.localeText : {}}
            slots={{
              toolbar: CustomToolbar,
              loadingOverlay: LinearProgress
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              }
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(model) =>
              setColumnVisibilityModel(model)
            }
          />
        </Paper>
        {/* </Paper> */}
      </Grid>
      <ServiceUpdate values={selectedRow} open={openUpdate} setOpen={setOpenUpdate} />
      <ServiceDelete values={selectedRow} open={openDelete} setOpen={setOpenDelete} />
    </Grid>
  );
}

export default Service;