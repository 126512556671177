import * as Yup from "yup";
import i18n from 'i18next';

// Yup.addMethod(Yup.mixed, 'uniqueIn', function (array = [], message) {
//   return this.test('uniqueIn', message, function (value) {
//     return array.filter(item => item === value).length < 2;
//   });
// });

// Yup.addMethod(Yup.string, 'isObject', function (message) {
//   return this.test('isObject', message, function (value) {
//     try {
//       JSON.parse(value);
//       return true;
//     } catch (error) {
//       return false;
//     }
//   });
// });

export function loadYupLocale() {
  Yup.setLocale({
    mixed: {
      required: i18n.t("__validation_required"),
    },
    string: {
      email: i18n.t("__validation_email"),
      min: ({ min }) => i18n.t("__validation_string_min", { value: min }),
      max: ({ max }) => i18n.t("__validation_string_max", { value: max }),
    },
    number: {
      min: ({ min }) => i18n.t("__validation_number_min", { value: min }),
      max: ({ max }) => i18n.t("__validation_number_max", { value: max }),
    },
    array: {
      min: ({ min }) => i18n.t("__validation_array_min", { value: min }),
      length: ({ length }) => i18n.t("__validation_array_min", { value: length }),
    },
  });
}

i18n.on('initialized', () => loadYupLocale());