import React, { useState } from 'react';
import { Grid, Box, Button, IconButton, Paper, LinearProgress, Tooltip, Avatar, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridActionsCellItem, esES, GridToolbarContainer, GridSelectedRowCount, GridPagination, GridFooterContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useDidListQuery, useDidInfoQuery } from 'services/api';
import { stringMaskFormat } from 'utils/utils';
import { common, blue, green, red } from "@mui/material/colors";
import DidCreate from './didCreate/DidCreate';
import DidUpdate from './didUpdate/DidUpdate';
import DidDelete from './didDelete/DidDelete';
import DidOTP from './didOTP/DidOTP';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import PhoneForwardedOutlinedIcon from '@mui/icons-material/PhoneForwardedOutlined';
import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import i18n from 'services/i18n';


export function Did() {
  const { t } = useTranslation();
  const { data: didList, isFetching: isDidListFetching } = useDidListQuery();
  const { data: didInfo, isFetching: isDidInfoFetching } = useDidInfoQuery();
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [openCreate, setOpenCreate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openOTP, setOpenOTP] = useState(false);


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'end' }}>
          <Button size='small' startIcon={<AddOutlinedIcon />} onClick={() => setOpenCreate(true)}>
            {t('__add')}
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }

  function CustomFooter() {
    return (
      <GridFooterContainer sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        {Boolean(selectedRowIds.length) ?
          <Box sx={{ display: 'flex' }}>
            <Tooltip title={t('__delete')}>
              <IconButton
                color="error"
                aria-label="open drawer"
                onClick={() => setOpenDelete(true)}
                sx={{ ml: 0.7 }}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
            <GridSelectedRowCount selectedRowCount={selectedRowIds.length} />
          </Box>
          :
          <Box></Box>
        }
        <GridPagination sx={{ flex: 1 }} />
      </GridFooterContainer>
    );
  }

  const columns = [
    {
      field: 'uuid',
      headerName: t("UUID"),
      flex: 1
    },
    {
      field: 'name',
      headerName: t("__did_form_name"),
      flex: 1,
    },
    {
      field: 'did',
      headerName: t("__did_form_did"),
      renderCell: (params) => (
        <>
          {params.row?.prefix?.iso_code &&
            <Tooltip title={params.row?.prefix?.country}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${params.row?.prefix?.iso_code?.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${params.row?.prefix?.iso_code?.toLowerCase()}.png 2x`}
                alt=""
              />
            </Tooltip>
          }
          <Typography variant="body2" sx={{ ml: 1 }}>
            {params.row?.prefix?.country_code && ' (+' + params.row?.prefix?.country_code + ')'} {stringMaskFormat(params.row?.did, params.row?.prefix?.mask)}
          </Typography>
        </>
      ),
      flex: 1
    },
    {
      field: 'description',
      headerName: t("__did_form_description"),
      flex: 1
    },
    {
      field: 'assigned',
      headerName: t("__did_form_assigned"),
      flex: 1,
      renderCell: (params) => (
        params.row.assigned ? <CheckOutlinedIcon color='success' /> : <CloseOutlinedIcon color='error' />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t("__actions"),
      renderHeader: () => (''),
      // width: 50,
      // maxWidth: 50,
      flex: 1,
      align: 'right',
      getActions: (params) => [
        <Tooltip title={t('__verify')} sx={{ display: params.row.verified ? 'none' : 'block' }}>
          <GridActionsCellItem
            icon={<WarningAmberOutlinedIcon />}
            label={t('__verify')}
            color='warning'
            onClick={() => {
              setSelectedRow(params.row);
              setTimeout(() => { setOpenOTP(true); }, 0);
            }}
          />
        </Tooltip>,
        <Tooltip title={t('__edit')}>
          <GridActionsCellItem
            icon={<EditOutlinedIcon />}
            label={t('__edit')}
            onClick={() => {
              setSelectedRow(params.row);
              setTimeout(() => { setOpenUpdate(true); }, 0);
            }}
          />
        </Tooltip>,
        <Tooltip title={t('__delete')}>
          <GridActionsCellItem
            icon={<DeleteOutlinedIcon />}
            label={t('__delete')}
            color='error'
            onClick={() => {
              setSelectedRow(params.row);
              setTimeout(() => { setOpenDelete(true) }, 0);
            }}
          />
        </Tooltip>,
      ],
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    uuid: false,
  });

  return (
    <Grid container spacing={3}>

      <Grid item xs={12} sm={12} md={4}>
        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          {isDidInfoFetching && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Avatar sx={{ bgcolor: blue[700], color: common.white }} variant="rounded">
              <PhoneOutlinedIcon />
            </Avatar>
            <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', ml: 2, fontWeight: 'bold', /* color: 'dimgray' */ }}>
              {isDidInfoFetching ?
                <CircularProgress size={24} />
                :
                didInfo?.data?.dids_total
              }
            </Typography>
          </Box>
          <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__did_info_total')}</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          {isDidInfoFetching && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Avatar sx={{ bgcolor: green[700], color: common.white }} variant="rounded">
              <PhoneForwardedOutlinedIcon />
            </Avatar>
            <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', ml: 2, fontWeight: 'bold', /* color: 'dimgray' */ }}>
              {isDidInfoFetching ?
                <CircularProgress size={24} />
                :
                didInfo?.data?.dids_assigned
              }
            </Typography>
          </Box>
          <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__did_info_assigned')}</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          {isDidInfoFetching && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Avatar sx={{ bgcolor: red[700], color: common.white }} variant="rounded">
              <AddIcCallOutlinedIcon />
            </Avatar>
            <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', ml: 2, fontWeight: 'bold', /* color: 'dimgray' */ }}>
              {isDidInfoFetching ?
                <CircularProgress size={24} />
                :
                didInfo?.data?.dids_unassigned
              }
            </Typography>
          </Box>
          <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__did_info_unassigned')}</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        {/* <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}> */}
        {/* <Typography variant="h6">Listado de DID's</Typography> */}
        {/* <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography> */}
        <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
          <DataGrid
            rows={didList?.data?.dids || []}
            getRowId={row => row.uuid}
            columns={columns}
            loading={isDidListFetching}
            autoHeight
            rowSelection
            checkboxSelection
            onRowSelectionModelChange={(params) => setSelectedRowIds(params)}
            localeText={i18n.language === 'es' ? esES.components.MuiDataGrid.defaultProps.localeText : {}}
            slots={{
              toolbar: CustomToolbar,
              footer: CustomFooter,
              loadingOverlay: LinearProgress
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              }
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(model) =>
              setColumnVisibilityModel(model)
            }
          />
        </Paper>
        {/* </Paper> */}
      </Grid>
      <DidCreate open={openCreate} setOpen={setOpenCreate} />
      <DidUpdate values={selectedRow} setValues={setSelectedRow} open={openUpdate} setOpen={setOpenUpdate} />
      <DidDelete values={selectedRow} setValues={setSelectedRow} valuesIds={selectedRowIds} open={openDelete} setOpen={setOpenDelete} />
      <DidOTP values={selectedRow} setValues={setSelectedRow} open={openOTP} setOpen={setOpenOTP} />
    </Grid>
  );
}

export default Did;

