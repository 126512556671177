import { createSlice } from '@reduxjs/toolkit';
import { api } from 'services/api';
import dayjs from 'dayjs';

const initialFilter = {
  date: {
    interval: 'today',
    dateFrom: dayjs().startOf('day'),
    dateTo: dayjs().endOf('day'),
  },
  campaign: [],
  did: [],
  widget: [],
  trackStatus: [],
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
};

const initialState = {
  reports: [],
  initialFilter: initialFilter,
  filter: initialFilter
};

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.getReport.matchPending, (state, action) => {
      state.reports = { ...state.reports, [action.meta?.arg?.originalArgs?.reportId]: ['loading'] };
    });
    builder.addMatcher(api.endpoints.getReport.matchFulfilled, (state, action) => {
      state.reports = { ...state.reports, [action.meta?.arg?.originalArgs?.reportId]: action.payload };
    });
    builder.addMatcher(api.endpoints.getReport.matchRejected, (state, action) => {
      state.reports = { ...state.reports, [action.meta?.arg?.originalArgs?.reportId]: [] };
    });
  },
});

export const { setFilter } = statisticsSlice.actions;

export const selectReports = (state) => state.statistics.reports;
export const selectFilter = (state) => state.statistics.filter;
export const selectInitialFilter = (state) => state.statistics.initialFilter;

export default statisticsSlice.reducer;
