import React, { useState, useEffect } from 'react';
import { Grid, Paper, IconButton, LinearProgress, Tooltip, Typography, ListItemButton, List, Pagination, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Box, ListItemIcon, Table, TableRow, TableCell, TableBody, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLazyTrackListQuery, useTrackDetailQuery, useLazyGetReportQuery } from 'services/api';
import { getUser } from 'services/localStorage';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AdsClickOutlinedIcon from '@mui/icons-material/AdsClickOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import AlarmOffOutlinedIcon from '@mui/icons-material/AlarmOffOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import TrackFilter from './trackFilter/TrackFilter';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as mTZ from 'moment-timezone';
import * as _ from 'lodash';
import darkTheme from 'highcharts/themes/dark-unica';
window.moment = mTZ; // Required by Highcharts

require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/highcharts-more")(Highcharts);
require("highcharts/highcharts-3d")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/offline-exporting")(Highcharts);
require("highcharts/modules/boost")(Highcharts);
require("highcharts/modules/funnel")(Highcharts);
require("highcharts/modules/timeline")(Highcharts);

export function Track() {
  const [page, setPage] = useState(1);
  const [fetchTrackList, { data: trackList, isFetching: isTrackListFetching }] = useLazyTrackListQuery();
  const [selectedTrack, setSelectedTrack] = React.useState(null);
  const { data: trackDetail, isFetching: isTrackDetailFetching } = useTrackDetailQuery(selectedTrack?.uuid, { skip: !selectedTrack?.uuid });
  const filter = useSelector((state) => state.track.filter);
  const [showFilters, setShowFilters] = React.useState(false);
  const reportIds = ['30101'];
  const reports = useSelector((state) => state.statistics.reports);
  const themeMode = useSelector((state) => state.app.theme);
  const [fetchReport] = useLazyGetReportQuery();
  const { t } = useTranslation();

  useEffect(() => {
    setPage(1);
    fetchTrackList({ page: 1, filter: btoa(JSON.stringify(filter)) });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    getReports();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTrack]);

  useEffect(() => {
    if (themeMode === 'dark') darkTheme(Highcharts);
  }, [themeMode]);

  function getReports() {
    if (!Object.values(reports).some(report => report.loading)) {
      reportIds.forEach((reportId) => {
        fetchReport({ reportId: reportId, clientId: getUser()?.clients?.[0]?.uuid, filter: btoa(JSON.stringify({ trackUuid: selectedTrack?.uuid, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })) });
      });
    }
  }

  function hasFilters() {
    let hasFilters = false;
    for (const [key, value] of Object.entries(filter)) {
      if ((key === 'date' && value?.interval !== 'today') || ((key === 'campaign' || key === 'did' || key === 'widget' || key === 'trackStatus' || key === 'trackUuid' || key === 'url') && Boolean(value.length))) hasFilters = true;
    }
    return hasFilters;
  }

  function getTrackStatusIcon(status) {
    switch (status) {
      case 'IMPRESSION':
        return <VisibilityOutlinedIcon />;
      case 'CLICK':
        return <AdsClickOutlinedIcon />;
      case 'BUSY':
        return <DoDisturbOnOutlinedIcon />;
      case 'OUT_OF_TIME':
        return <AlarmOffOutlinedIcon />;
      case 'IN_PROGRESS':
        return <UpdateOutlinedIcon />;
      case 'RANKING':
        return <StarOutlineIcon />;
      case 'DONE':
        return <TaskAltOutlinedIcon />;
      case 'END':
        return <AssignmentTurnedInOutlinedIcon />;
      case 'IN_CALL':
        return <PhoneOutlinedIcon />;
      case 'ERROR':
        return <ErrorOutlineOutlinedIcon />;
      default:
        return '';
    }
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', textAlign: 'right' }}>
          <IconButton
            size="large"
            onClick={(e) => setShowFilters(!showFilters)}
          >
            <Tooltip title={t('__filter')}>
              {hasFilters() ? <FilterAltOutlinedIcon color='primary' /> : <FilterAltOffOutlinedIcon />}
            </Tooltip>
          </IconButton>
        </Grid>

        <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
          <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column' }}>
            {isTrackListFetching && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
            {/* <Typography variant="h6">Tracks</Typography> */}
            {/* <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__service_add_subtitle')}</Typography> */}

            <List component="nav">
              {isTrackListFetching ?
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress size={32} />
                </Box>
                :
                Boolean(!trackList?.data?.tracks?.length) ?
                  <Typography variant='body1' sx={{ textAlign: 'center' }}>{t('__track_empty')}</Typography>
                  :
                  trackList?.data?.tracks?.map((track, trackIndex) =>
                    <Box key={track?.uuid}>
                      {(dayjs(trackList?.data?.tracks?.[trackIndex - 1]?.createdAt).format('D') !== dayjs(track?.createdAt).format('D')) &&
                        <>
                          {trackIndex === 0 && <Divider />}
                          <Typography variant='body2' sx={{ textAlign: 'center', p: 1, fontWeight: 'bold', textTransform: 'capitalize' }}>{dayjs(track?.createdAt).format('ddd D MMM YYYY')}</Typography>
                          <Divider />
                        </>
                      }
                      <ListItemButton
                        key={track?.uuid}
                        selected={selectedTrack?.uuid === track?.uuid}
                        onClick={() => setSelectedTrack(track)}
                        sx={{ borderRadius: '5px' }}
                      >
                        <ListItemIcon>
                          <Tooltip title={t(track?.status?.name_t)}>
                            {getTrackStatusIcon(track?.status?.name)}
                          </Tooltip>
                        </ListItemIcon>
                        <Box>
                          <Typography variant="body2">{track?.campaign?.name}</Typography>
                          <Typography variant="body2" sx={{ color: 'gray', textTransform: 'capitalize' }}>{dayjs(track?.createdAt).format('ddd D MMM YYYY HH:mm:ss')}</Typography>
                        </Box>
                      </ListItemButton>
                      <Divider />
                    </Box>
                  )
              }
            </List>

            {Boolean(trackList?.data?.tracks?.length) &&
              <Pagination
                count={trackList?.data?.totalPages}
                size="small"
                page={page}
                onChange={(e, page) => { setPage(page); fetchTrackList({ page: page, filter: btoa(JSON.stringify(filter)) }) }}
                sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}
              />
            }
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={7} lg={8} xl={9} >
          {selectedTrack ?
            isTrackDetailFetching ?
              <Box sx={{ position: 'relative' }}>
                <CircularProgress size={32} sx={{ mt: 4 }} />
                <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />
              </Box>
              :
              <>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{t('__track_data')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table>
                      <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">{t('__track_uuid')}</TableCell>
                          <TableCell align="right"><Typography component="span" variant='body2' color="primary" sx={{ fontWeight: 'bold' }}>{t(trackDetail?.data?.track?.uuid)}</Typography></TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">{t('__track_type')}</TableCell>
                          <TableCell align="right"><Typography component="span" variant='body2' color="primary" sx={{ fontWeight: 'bold' }}>{t(trackDetail?.data?.track?.status?.name_t)}</Typography></TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">{t('__track_creation')}</TableCell>
                          <TableCell align="right"><Typography component="span" variant='body2' color="primary" sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{dayjs(trackDetail?.data?.track?.createdAt).format('ddd D MMM YYYY HH:mm:ss')}</Typography></TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">{t('__track_last_update')}</TableCell>
                          <TableCell align="right"><Typography component="span" variant='body2' color="primary" sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{dayjs(trackDetail?.data?.track?.updatedAt).format('ddd D MMM YYYY HH:mm:ss')}</Typography></TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">{t('__track_campaign')}</TableCell>
                          <TableCell align="right"><Typography component="span" variant='body2' color="primary" sx={{ fontWeight: 'bold' }}>{trackDetail?.data?.track?.campaign?.name}</Typography></TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">{t('__track_did')}</TableCell>
                          <TableCell align="right"><Typography component="span" variant='body2' color="primary" sx={{ fontWeight: 'bold' }}>{trackDetail?.data?.track?.campaign?.did?.name}</Typography></TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">{t('__track_schedule')}</TableCell>
                          <TableCell align="right"><Typography component="span" variant='body2' color="primary" sx={{ fontWeight: 'bold' }}>{trackDetail?.data?.track?.campaign?.schedule?.name}</Typography></TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">{t('__track_widget')}</TableCell>
                          <TableCell align="right"><Typography component="span" variant='body2' color="primary" sx={{ fontWeight: 'bold' }}>{trackDetail?.data?.track?.campaign?.widget?.name}</Typography></TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">{t('__track_domain')}</TableCell>
                          <TableCell align="right"><Typography component="span" variant='body2' color="primary" sx={{ fontWeight: 'bold' }}>{trackDetail?.data?.track?.campaign?.domain?.name}</Typography></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{t('__track_info')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table>
                      <TableBody>
                        {trackDetail?.data?.track?.trackInfo?.map(trackInfo =>
                          <TableRow key={trackInfo?.param?.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">{trackInfo?.param?.name}</TableCell>
                            <TableCell align="right"><Typography component="span" variant='body2' color="primary" sx={{ fontWeight: 'bold' }}>{trackInfo?.value}</Typography></TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{t('__track_call')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table>
                      <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">{t('__track_call_duration')}</TableCell>
                          <TableCell align="right"><Typography component="span" variant='body2' color="primary" sx={{ fontWeight: 'bold' }}>{trackDetail?.data?.track?.calls?.[0]?.duration || 0}s</Typography></TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">{t('__track_call_start')}</TableCell>
                          <TableCell align="right"><Typography component="span" variant='body2' color="primary" sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{dayjs(trackDetail?.data?.track?.calls?.[0]?.startedAt).format('ddd D MMM YYYY HH:mm:ss')}</Typography></TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">{t('__track_call_end')}</TableCell>
                          <TableCell align="right"><Typography component="span" variant='body2' color="primary" sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{dayjs(trackDetail?.data?.track?.calls?.[0]?.finishedAt).format('ddd D MMM YYYY HH:mm:ss')}</Typography></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{t('__track_statistics')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {Object.keys(reports).map((reportId) =>
                      reportIds.includes(reportId) &&
                      <Grid key={reportId} item xs={12}>
                        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 0 }}>
                          {reports[reportId]?.[0] === 'loading' && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
                          {/* <Typography variant="h6">{reportId}</Typography> */}
                          {/* <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__service_add_subtitle')}</Typography> */}
                          {reports[reportId]?.[0] !== 'loading' &&
                            <HighchartsReact key={reportId} highcharts={Highcharts} options={_.cloneDeep(reports[reportId])} />
                          }
                        </Paper>
                      </Grid>
                    )}
                  </AccordionDetails>
                </Accordion>
              </>
            :
            Boolean(trackList?.data?.tracks?.length) &&
            <Accordion expanded={false} sx={{ display: 'flex', justifyContent: 'center' }}>
              <AccordionSummary>
                <Typography>{t('__track_select')}</Typography>
              </AccordionSummary>
            </Accordion>
          }
        </Grid>
      </Grid>

      {/* FILTERS */}
      {showFilters &&
        <TrackFilter showFilters={showFilters} setShowFilters={setShowFilters} />
      }
    </>
  );
}

export default Track;
