import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import ScheduleForm from '../scheduleForm/ScheduleForm';
import { useScheduleUpdateMutation } from 'services/api';
import * as Yup from 'yup';


function ScheduleUpdate(props) {
  const { t } = useTranslation();
  const { values, setValues, open, setOpen } = props;
  const [scheduleUpdate, { isLoading, error }] = useScheduleUpdateMutation();

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      scheduleData: [],
      scheduleDate: []
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      description: Yup.string().required(),
      scheduleData: Yup.array(
        Yup.object({
          dayType: Yup.string().required(),
          timeFrom: Yup.date().required(),
          timeTo: Yup.date().required(),
        }),
      ),
      scheduleDate: Yup.array(
        Yup.object({
          scheduleDate: Yup.string().required(),
          timeFrom: Yup.date().required(),
          timeTo: Yup.date().required(),
        }),
      ),
    }),
    onSubmit: (values) => {
      const payload = { ...values };
      delete payload.assigned;
      scheduleUpdate(payload).unwrap().then(() => {
        close();
      }).catch(() => { });
    },
  });

  useEffect(() => {
    if (open) formik.setValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, open]);

  function close() {
    setOpen(false);
    formik.resetForm();
    setValues({});
  }

  return (
    <Dialog open={open} onClose={() => !isLoading && close()} fullWidth maxWidth="md">
      {isLoading && <LinearProgress />}
      <DialogTitle>{t("__schedule_update_title")}</DialogTitle>
      <DialogContent>
        <ScheduleForm formik={formik} error={error?.data} />
      </DialogContent>
      <DialogActions sx={{ pr: 3, pl: 3, pb: 3 }}>
        <Button disabled={isLoading} onClick={() => close()}>{t("__cancel")}</Button>
        <Button variant="outlined" disabled={isLoading} onClick={formik.handleSubmit}>{t("__accept")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ScheduleUpdate;