import { common } from "@mui/material/colors";
// import { alpha } from '@mui/material/styles';

const getTheme = (mode) => ({
  ...(mode === 'light' ? {
    // values for light mode
    palette: {
      mode,
      // background: {
      //   default: "#fcfcfc"
      // },
      primary: {
        main: '#ff9900',
        contrastText: common.white
      },
      // secondary: {
      //   main: '#816e99',
      // },
      // error: {
      //   main: '#e95184',
      // },
      // warning: {
      //   main: '#fef84b',
      // },
      // info: {
      //   main: '#76e7f9',
      // },
      // success: {
      //   main: '#7ef7aa',
      // },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            position: 'relative',
            // backgroundColor: '#fcfcfc',
          }
        }
      },

      // MuiPaper: {
      //   styleOverrides: {
      //     root: ({ ownerState, theme }) => ({
      //       backgroundColor: common.white,
      //       color: common.black
      //     }),
      //   }
      // },

      MuiDataGrid: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
              outline: "none"
            },
            "& .MuiDataGrid-row": {
              cursor: "pointer"
            },
            padding: '24px',
            border: 'none',
            borderRadius: '4px',
            boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
          }),
        }
      },
      MuiAppBar: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            backgroundColor: common.white,
            color: common.black
          }),
        }
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            ...(theme.palette[ownerState.color] && {
              "&:hover": {
                // backgroundColor: theme.palette[ownerState.color].main,
                // color: common.white,
              }
            }),
          })
        }
      },
      MuiListItemButton: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            "&:hover": {
              // backgroundColor: alpha(theme.palette.primary.main, 0.2),
              color: theme.palette.primary.main,
            },
            "&:hover .MuiListItemIcon-root": {
              color: theme.palette.primary.main,
              // color: common.white,
            },
            "&.Mui-selected": {
              color: theme.palette.primary.main,
              backgroundColor: '#f7f7f7'
            },
            "&.Mui-selected .MuiListItemIcon-root": {
              color: theme.palette.primary.main
            },
            "&.Mui-selected .MuiTypography-root": {
              // fontWeight: '600'
            }
          })
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            "&:hover": {
              // backgroundColor: alpha(theme.palette.primary.main, 0.2),
              color: theme.palette.primary.main,
            },
            "&:hover .MuiListItemIcon-root": {
              color: theme.palette.primary.main,
            }
          })
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            ...(theme.palette[ownerState.hovercolor] && {
              "&:hover": {
                // backgroundColor: alpha(theme.palette[ownerState.hovercolor].main, 0.2),
                color: theme.palette[ownerState.hovercolor].main,
              }
            }),
          })
        }
      },
    },
  } : {
    // values for dark mode
    palette: {
      mode,
      // background: {
      //   default: "#353535"
      // },
      primary: {
        main: '#ff9900',
        contrastText: common.white
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            position: 'relative',
            // backgroundColor: '#121212',
            // color: common.black
          }),
        }
      },
      MuiDataGrid: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
              outline: "none"
            },
            "& .MuiDataGrid-row": {
              cursor: "pointer"
            },
            padding: '24px',
            border: 'none',
            borderRadius: '4px',
            boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
          }),
        }
      },

      MuiButton: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            ...(theme.palette[ownerState.color] && {
              "&:hover": {
                // backgroundColor: theme.palette[ownerState.color].main,
                color: common.white,
              }
            }),
          })
        }
      },
      MuiListItemButton: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            "&:hover": {
              // backgroundColor: alpha(theme.palette.primary.main, 0.2),
              color: theme.palette.primary.main,
            },
            "&:hover .MuiListItemIcon-root": {
              color: theme.palette.primary.main,
            },
            "&.Mui-selected": {
              color: theme.palette.primary.main,
            },
            "&.Mui-selected .MuiListItemIcon-root": {
              color: theme.palette.primary.main
            },
            "&.Mui-selected .MuiTypography-root": {
              // fontWeight: '600'
            }
          })
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            "&:hover": {
              // backgroundColor: alpha(theme.palette.primary.main, 0.2),
              color: theme.palette.primary.main,
            },
            "&:hover .MuiListItemIcon-root": {
              color: theme.palette.primary.main,
            }
          })
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            ...(theme.palette[ownerState.hovercolor] && {
              "&:hover": {
                // backgroundColor: alpha(theme.palette[ownerState.hovercolor].main, 0.2),
                color: theme.palette[ownerState.hovercolor].main,
              }
            }),
          })
        }
      },
    },
  })

});

export { getTheme };

