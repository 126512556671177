import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, TextField, Box, Typography } from '@mui/material';
import { useLoginForgotMutation } from 'services/api';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';


export function Forgot(props) {
  const { t } = useTranslation();
  const { open, setOpen } = props;
  const [loginForgot, { isLoading: isLoginForgotLoading, error: loginForgotError }] = useLoginForgotMutation();

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(),
    }),
    onSubmit: (values) => {
      const payload = { ...values };
      loginForgot(payload).unwrap().then(() => {
        close();
        formik.resetForm();
      });
    },
  });

  function close() {
    setOpen(false);
    formik.resetForm();
  }

  return (
    <Dialog open={open} onClose={() => !isLoginForgotLoading && close()} fullWidth maxWidth="sm">
      {isLoginForgotLoading && <LinearProgress />}
      <DialogTitle>{t("__login_forgot_title")}</DialogTitle>
      <DialogContent>
        <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__login_forgot_subtitle')}</Typography>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          id="loginForgotForm"
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            label={t("__login_forgot_form_email")}
            margin="normal"
            fullWidth
            autoFocus
            {...formik.getFieldProps('email')}
            error={Boolean(formik.submitCount) && (Boolean(formik.errors.email) || Boolean(loginForgotError?.data?.error_details?.email))}
            helperText={Boolean(formik.submitCount) && (formik.errors.email || loginForgotError?.data?.error_details?.email)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoginForgotLoading} onClick={() => close()}>{t("__cancel")}</Button>
        <Button variant="outlined" disabled={isLoginForgotLoading} onClick={formik.handleSubmit}>{t("__login_forgot_button_submit")}</Button>
      </DialogActions>
    </Dialog>
  );
}