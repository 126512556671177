import React from 'react';
import { Grid, Drawer, Typography, Button, Autocomplete, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useCampaignListQuery, useDidListQuery, useWidgetListQuery, useTrackStatusListQuery } from 'services/api';
import { useSelector, useDispatch } from 'react-redux';
import { setFilter } from 'slices/track.slice';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { dateInterval } from 'utils/mock';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import * as Yup from 'yup';
import dayjs from 'dayjs';

export function TrackFilter(props) {
  const dispatch = useDispatch();
  const { showFilters, setShowFilters } = props;
  const filter = useSelector((state) => state.track.filter);
  const initialFilter = useSelector((state) => state.track.initialFilter);
  const { data: campaignList, isFetching: isCampaignListFetching } = useCampaignListQuery();
  const { data: didList, isFetching: isDidListFetching } = useDidListQuery();
  const { data: widgetList, isFetching: isWidgetListFetching } = useWidgetListQuery();
  const { data: trackStatusList, isFetching: isTrackStatusListFetching } = useTrackStatusListQuery();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      date: {
        interval: filter?.date?.interval || 'today',
        dateFrom: filter?.date?.dateFrom || dayjs().startOf('day'),
        dateTo: filter?.date?.dateTo || dayjs().endOf('day'),
      },
      campaign: filter?.campaign?.map((campaign) => campaignList?.data?.campaigns?.find((_campaign) => _campaign.uuid === campaign)) || initialFilter.campaign,
      did: filter?.did?.map((did) => didList?.data?.dids?.find((_did) => _did.uuid === did)) || initialFilter.did,
      widget: filter?.widget?.map((widget) => widgetList?.data?.widgets?.find((_widget) => _widget.uuid === widget)) || initialFilter.widget,
      trackStatus: filter?.trackStatus?.map((trackStatus) => trackStatusList?.data?.status?.find((_trackStatus) => _trackStatus.id === trackStatus)) || initialFilter.trackStatus,
      trackUuid: filter?.trackUuid || '',
      url: filter?.url || ''
    },
    validationSchema: Yup.object({
      date: Yup.object({
        interval: Yup.string(),
        dateFrom: Yup.date(),
        dateTo: Yup.date(),
      }),
      campaign: Yup.array(),
      did: Yup.array(),
      widget: Yup.array(),
      trackStatus: Yup.array(),
      trackUuid: Yup.string(),
      url: Yup.string(),
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
        campaign: values.campaign?.map((campaign) => campaign.uuid),
        did: values.did?.map((did) => did.uuid),
        widget: values.widget?.map((widget) => widget.uuid),
        trackStatus: values.trackStatus?.map((trackStatus) => trackStatus.id),
        trackUuid: values.trackUuid,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      };
      dispatch(setFilter(payload));
      close();
    },
  });

  const handleSetInterval = (interval) => {
    switch (interval?.name) {
      case 'today':
        formik.setValues({ ...formik.values, date: { ...formik.values.date, interval: interval?.name, dateFrom: dayjs().startOf('day'), dateTo: dayjs().endOf('day') } });
        break;
      case 'custom':
        formik.setValues({ ...formik.values, date: { ...formik.values.date, interval: interval?.name } });
        break;
      case 'last30m':
        formik.setValues({ ...formik.values, date: { ...formik.values.date, interval: interval?.name, dateFrom: dayjs().subtract(30, 'minute'), dateTo: dayjs() } });
        break;
      case 'lastHour':
        formik.setValues({ ...formik.values, date: { ...formik.values.date, interval: interval?.name, dateFrom: dayjs().subtract(60, 'minute'), dateTo: dayjs() } });
        break;
      case 'yesterday':
        formik.setValues({ ...formik.values, date: { ...formik.values.date, interval: interval?.name, dateFrom: dayjs().startOf('day').subtract(1, 'day'), dateTo: dayjs().endOf('day').subtract(1, 'day') } });
        break;
      case 'thisWeek':
        formik.setValues({ ...formik.values, date: { ...formik.values.date, interval: interval?.name, dateFrom: dayjs().startOf('week'), dateTo: dayjs() } });
        break;
      case 'last7d':
        formik.setValues({ ...formik.values, date: { ...formik.values.date, interval: interval?.name, dateFrom: dayjs().subtract(7, 'day'), dateTo: dayjs() } });
        break;
      case 'thisMonth':
        formik.setValues({ ...formik.values, date: { ...formik.values.date, interval: interval?.name, dateFrom: dayjs().startOf('month'), dateTo: dayjs() } });
        break;
      case 'lastMonth':
        formik.setValues({ ...formik.values, date: { ...formik.values.date, interval: interval?.name, dateFrom: dayjs().startOf('month').subtract(1, 'month'), dateTo: dayjs().endOf('month').subtract(1, 'month') } });
        break;
      case 'last30d':
        formik.setValues({ ...formik.values, date: { ...formik.values.date, interval: interval?.name, dateFrom: dayjs().subtract(30, 'day'), dateTo: dayjs() } });
        break;
      case 'last3Months':
        formik.setValues({ ...formik.values, date: { ...formik.values.date, interval: interval?.name, dateFrom: dayjs().subtract(3, 'month'), dateTo: dayjs() } });
        break;
      case 'last6Months':
        formik.setValues({ ...formik.values, date: { ...formik.values.date, interval: interval?.name, dateFrom: dayjs().subtract(6, 'month'), dateTo: dayjs() } });
        break;
      case 'lastYear':
        formik.setValues({ ...formik.values, date: { ...formik.values.date, interval: interval?.name, dateFrom: dayjs().subtract(1, 'year'), dateTo: dayjs() } });
        break;
      case 'last2Years':
        formik.setValues({ ...formik.values, date: { ...formik.values.date, interval: interval?.name, dateFrom: dayjs().subtract(2, 'year'), dateTo: dayjs() } });
        break;
      case 'last3Years':
        formik.setValues({ ...formik.values, date: { ...formik.values.date, interval: interval?.name, dateFrom: dayjs().subtract(3, 'year'), dateTo: dayjs() } });
        break;
      case 'last4Years':
        formik.setValues({ ...formik.values, date: { ...formik.values.date, interval: interval?.name, dateFrom: dayjs().subtract(4, 'year'), dateTo: dayjs() } });
        break;
      case 'last5Years':
        formik.setValues({ ...formik.values, date: { ...formik.values.date, interval: interval?.name, dateFrom: dayjs().subtract(5, 'year'), dateTo: dayjs() } });
        break;
      default:
        break;
    }
  };

  function close() {
    setShowFilters(false);
  }

  return (
    <Drawer
      anchor='right'
      open={showFilters}
      onClose={() => close()}
      sx={{
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 300, p: 3 },
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <TuneOutlinedIcon sx={{ mr: 1 }} />
          <Typography variant='h6'>{t('__filter')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            options={dateInterval}
            getOptionLabel={(label) => t(label?.name_t) || ''}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            noOptionsText={t('__no_options')}
            value={dateInterval.find((interval) => formik.values?.date?.interval === interval.name) || null}
            onChange={(e, value) => {
              handleSetInterval(value);
            }}
            renderInput={(params) => <TextField
              {...params}
              label={t('__track_form_interval')}
              error={Boolean(formik.submitCount) && Boolean(formik.errors?.date?.interval)}
              helperText={Boolean(formik.submitCount) && formik.errors?.date?.interval}
            />}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {t(option?.name_t)}
              </li>
            )}
          />
        </Grid>

        {formik.values.date?.interval === 'custom' &&
          <>
            <Grid item xs={12}>
              <DateTimePicker
                label={t('__track_form_dateFrom')}
                value={dayjs(formik.values?.date?.dateFrom)}
                onChange={(value) => formik.setFieldValue(`date.dateFrom`, dayjs(value))}
                slotProps={{
                  textField: {
                    error: Boolean(formik.submitCount) && Boolean(formik.errors?.date?.dateFrom),
                    helperText: Boolean(formik.submitCount) && formik.errors?.date?.dateFrom,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <DateTimePicker
                label={t('__track_form_dateTo')}
                value={dayjs(formik.values?.date?.dateTo)}
                onChange={(value) => formik.setFieldValue(`date.dateTo`, dayjs(value))}
                slotProps={{
                  textField: {
                    error: Boolean(formik.submitCount) && Boolean(formik.errors?.date?.dateTo),
                    helperText: Boolean(formik.submitCount) && formik.errors?.date?.dateTo,
                  },
                }}
              />
            </Grid>
          </>
        }

        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={campaignList?.data?.campaigns || []}
            getOptionLabel={(label) => label?.name || ''}
            isOptionEqualToValue={(option, value) => option?.uuid === value?.uuid}
            loading={isCampaignListFetching}
            loadingText={t('__loading')}
            noOptionsText={t('__no_options')}
            value={formik.values.campaign || []}
            onChange={(e, value) => formik.setFieldValue(`campaign`, value)}
            renderInput={(params) => <TextField
              {...params}
              label={t('__track_form_campaign')}
              error={Boolean(formik.submitCount) && Boolean(formik.errors.campaign)}
              helperText={Boolean(formik.submitCount) && formik.errors.campaign}
            />}
            renderOption={(props, option) => (
              <li {...props} key={option.uuid}>
                {option.name}
              </li>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={didList?.data?.dids || []}
            getOptionLabel={(label) => label?.name || ''}
            isOptionEqualToValue={(option, value) => option?.uuid === value?.uuid}
            loading={isDidListFetching}
            loadingText={t('__loading')}
            noOptionsText={t('__no_options')}
            value={formik.values.did || []}
            onChange={(e, value) => formik.setFieldValue(`did`, value)}
            renderInput={(params) => <TextField
              {...params}
              label={t('__track_form_did')}
              error={Boolean(formik.submitCount) && Boolean(formik.errors.did)}
              helperText={Boolean(formik.submitCount) && formik.errors.did}
            />}
            renderOption={(props, option) => (
              <li {...props} key={option.uuid}>
                {option.name}
              </li>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={widgetList?.data?.widgets || []}
            getOptionLabel={(label) => label?.name || ''}
            isOptionEqualToValue={(option, value) => option?.uuid === value?.uuid}
            loading={isWidgetListFetching}
            loadingText={t('__loading')}
            noOptionsText={t('__no_options')}
            value={formik.values.widget || []}
            onChange={(e, value) => formik.setFieldValue(`widget`, value)}
            renderInput={(params) => <TextField
              {...params}
              label={t('__track_form_widget')}
              error={Boolean(formik.submitCount) && Boolean(formik.errors.widget)}
              helperText={Boolean(formik.submitCount) && formik.errors.widget}
            />}
            renderOption={(props, option) => (
              <li {...props} key={option.uuid}>
                {option.name}
              </li>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={trackStatusList?.data?.status || []}
            getOptionLabel={(label) => t(label?.name_t) || ''}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            loading={isTrackStatusListFetching}
            loadingText={t('__loading')}
            noOptionsText={t('__no_options')}
            value={formik.values.trackStatus || null}
            onChange={(e, value) => formik.setFieldValue(`trackStatus`, value)}
            renderInput={(params) => <TextField
              {...params}
              label={t('__track_form_track_status')}
              error={Boolean(formik.submitCount) && Boolean(formik.errors.trackStatus)}
              helperText={Boolean(formik.submitCount) && formik.errors.trackStatus}
            />}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {t(option?.name_t)}
              </li>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={t("__track_form_url")}
            fullWidth
            {...formik.getFieldProps('url')}
            error={Boolean(formik.submitCount) && (Boolean(formik.errors.url) || Boolean(formik.errors?.url))}
            helperText={Boolean(formik.submitCount) && (formik.errors.url || formik.errors?.url)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={t("__track_form_uuid")}
            fullWidth
            {...formik.getFieldProps('trackUuid')}
            error={Boolean(formik.submitCount) && (Boolean(formik.errors.trackUuid) || Boolean(formik.errors?.trackUuid))}
            helperText={Boolean(formik.submitCount) && (formik.errors.trackUuid || formik.errors?.trackUuid)}
          />
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button sx={{ mr: 1 }} onClick={() => formik.setValues(initialFilter)}>{t("__clean")}</Button>
          <Button variant="outlined" onClick={formik.handleSubmit}>{t("__accept")}</Button>
        </Grid>

      </Grid>
    </Drawer >
  );
}

export default TrackFilter;
