import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useScheduleCreateMutation } from 'services/api';
import ScheduleForm from '../scheduleForm/ScheduleForm';
import * as Yup from 'yup';


function ScheduleCreate(props) {
  const { t } = useTranslation();
  const { values, open, setOpen } = props;
  const [scheduleCreate, { isLoading, error }] = useScheduleCreateMutation();

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      scheduleData: [],
      scheduleDate: []
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      description: Yup.string().required(),
      scheduleData: Yup.array(
        Yup.object({
          dayType: Yup.string().required(),
          timeFrom: Yup.date().required(),
          timeTo: Yup.date().required(),
        }),
      ),
      scheduleDate: Yup.array(
        Yup.object({
          scheduleDate: Yup.string().required(),
          timeFrom: Yup.date().required(),
          timeTo: Yup.date().required(),
        }),
      ),
    }),
    onSubmit: (values) => {
      const payload = { ...values };
      delete payload.assigned;
      delete payload.uuid;
      scheduleCreate(payload).unwrap().then(() => {
        close();
      }).catch(() => { });
    },
  });

  useEffect(() => {
    if (open && Boolean(Object.keys(values).length)) formik.setValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, open]);

  function close() {
    setOpen(false);
    formik.resetForm();
  }

  return (
    <Dialog open={open} onClose={() => !isLoading && close()} fullWidth maxWidth="md">
      {isLoading && <LinearProgress />}
      <DialogTitle>{t("__schedule_create_title")}</DialogTitle>
      <DialogContent>
        <ScheduleForm formik={formik} error={error?.data} />
      </DialogContent>
      <DialogActions sx={{ pr: 3, pl: 3, pb: 3 }}>
        <Button disabled={isLoading} onClick={() => close()}>{t("__cancel")}</Button>
        <Button variant="outlined" disabled={isLoading} onClick={formik.handleSubmit}>{t("__accept")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ScheduleCreate;