import React, { useState } from 'react';
import { Grid, Box, Button, IconButton, Paper, LinearProgress, Tooltip, Avatar, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridActionsCellItem, esES, GridToolbarContainer, GridSelectedRowCount, GridPagination, GridFooterContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useWidgetListQuery, useWidgetInfoQuery } from 'services/api';
import { common, blue, green, red } from "@mui/material/colors";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import WidgetCreate from './widgetCreate/WidgetCreate';
import WidgetUpdate from './widgetUpdate/WidgetUpdate';
import WidgetDelete from './widgetDelete/WidgetDelete';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import i18n from 'services/i18n';


export function Widget() {
  const { t } = useTranslation();
  const { data: widgetList, isFetching: isWidgetListFetching } = useWidgetListQuery();
  const { data: widgetInfo, isFetching: isWidgetInfoFetching } = useWidgetInfoQuery();
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [openCreate, setOpenCreate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'end' }}>
          <Button size='small' startIcon={<AddOutlinedIcon />} onClick={() => { setSelectedRow({}); setOpenCreate(true); }}>
            {t('__add')}
          </Button>
        </Box>
      </GridToolbarContainer>
    );
  }

  function CustomFooter() {
    return (
      <GridFooterContainer sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        {Boolean(selectedRowIds.length) ?
          <Box sx={{ display: 'flex' }}>
            <Tooltip title={t('__delete')}>
              <IconButton
                color="error"
                aria-label="open drawer"
                onClick={() => setOpenDelete(true)}
                sx={{ ml: 0.7 }}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
            <GridSelectedRowCount selectedRowCount={selectedRowIds.length} />
          </Box>
          :
          <Box />
        }
        <GridPagination sx={{ flex: 1 }} />
      </GridFooterContainer>
    );
  }

  const columns = [
    {
      field: 'uuid',
      headerName: t("ID"),
      flex: 1
    },
    {
      field: 'name',
      headerName: t("__widget_form_name"),
      flex: 1,
    },
    {
      field: 'description',
      headerName: t("__widget_form_description"),
      flex: 1
    },
    {
      field: 'assigned',
      headerName: t("__widget_form_assigned"),
      flex: 1,
      renderCell: (params) => (
        params.row.assigned ? <CheckOutlinedIcon color='success' /> : <CloseOutlinedIcon color='error' />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t("__actions"),
      renderHeader: () => (''),
      flex: 1,
      align: 'right',
      getActions: (params) => [
        <Tooltip title={t('__clone')}>
          <GridActionsCellItem
            icon={<ContentCopyOutlinedIcon />}
            label={t('__clone')}
            onClick={() => {
              setSelectedRow(params.row);
              setTimeout(() => { setOpenCreate(true); }, 0);
            }}
          />
        </Tooltip>,
        <Tooltip title={t('__edit')}>
          <GridActionsCellItem
            icon={<EditOutlinedIcon />}
            label={t('__edit')}
            onClick={() => {
              setSelectedRow(params.row);
              setTimeout(() => { setOpenUpdate(true); }, 0);
            }}
          />
        </Tooltip>,
        <Tooltip title={t('__delete')}>
          <GridActionsCellItem
            icon={<DeleteOutlinedIcon />}
            label={t('__delete')}
            color='error'
            onClick={() => {
              setSelectedRow(params.row);
              setTimeout(() => { setOpenDelete(true); }, 0);
            }}
          />
        </Tooltip>,
      ],
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    uuid: false,
  });

  return (
    <Grid container spacing={3}>

      <Grid item xs={12} sm={12} md={4}>
        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          {isWidgetInfoFetching && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Avatar sx={{ bgcolor: blue[700], color: common.white }} variant="rounded">
              <CalendarMonthOutlinedIcon />
            </Avatar>
            <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', ml: 2, fontWeight: 'bold', /* color: 'dimgray' */ }}>
              {isWidgetInfoFetching ?
                <CircularProgress size={24} />
                :
                widgetInfo?.data?.widgets_total
              }
            </Typography>
          </Box>
          <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__widget_info_total')}</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          {isWidgetInfoFetching && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Avatar sx={{ bgcolor: green[700], color: common.white }} variant="rounded">
              <EventAvailableOutlinedIcon />
            </Avatar>
            <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', ml: 2, fontWeight: 'bold', /* color: 'dimgray' */ }}>
              {isWidgetInfoFetching ?
                <CircularProgress size={24} />
                :
                widgetInfo?.data?.widgets_assigned
              }
            </Typography>
          </Box>
          <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__widget_info_assigned')}</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
          {isWidgetInfoFetching && <LinearProgress sx={{ width: '100%', position: 'absolute', top: '0', left: '0', borderRadius: '5px' }} />}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Avatar sx={{ bgcolor: red[700], color: common.white }} variant="rounded">
              <DateRangeOutlinedIcon />
            </Avatar>
            <Typography variant='h6' sx={{ display: 'flex', alignItems: 'center', ml: 2, fontWeight: 'bold', /* color: 'dimgray' */ }}>
              {isWidgetInfoFetching ?
                <CircularProgress size={24} />
                :
                widgetInfo?.data?.widgets_unassigned
              }
            </Typography>
          </Box>
          <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>{t('__widget_info_unassigned')}</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        {/* <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', p: 3 }}> */}
        {/* <Typography variant="h6">Listado de DID's</Typography> */}
        {/* <Typography variant='body2' sx={{ mt: 1, color: 'gray' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography> */}
        <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
          <DataGrid
            rows={widgetList?.data?.widgets || []}
            getRowId={row => row.uuid}
            columns={columns}
            loading={isWidgetListFetching}
            autoHeight
            rowSelection
            checkboxSelection
            onRowSelectionModelChange={(params) => setSelectedRowIds(params)}
            localeText={i18n.language === 'es' ? esES.components.MuiDataGrid.defaultProps.localeText : {}}
            slots={{
              toolbar: CustomToolbar,
              footer: CustomFooter,
              loadingOverlay: LinearProgress
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              }
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(model) =>
              setColumnVisibilityModel(model)
            }
          />
        </Paper>
        {/* </Paper> */}
      </Grid>
      <WidgetCreate values={selectedRow} open={openCreate} setOpen={setOpenCreate} />
      <WidgetUpdate values={selectedRow} setValues={setSelectedRow} open={openUpdate} setOpen={setOpenUpdate} />
      <WidgetDelete values={selectedRow} setValues={setSelectedRow} valuesIds={selectedRowIds} open={openDelete} setOpen={setOpenDelete} />
    </Grid>
  );
}

export default Widget;

