import React from 'react';
import { Grid, Drawer, Typography, Button, Autocomplete, TextField } from '@mui/material';
import { useCampaignListQuery, useDidListQuery, useWidgetListQuery, useTrackStatusListQuery } from 'services/api';
import { setFilter } from 'slices/supervision.slice';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import * as Yup from 'yup';

export function SupervisionFilter(props) {
  const dispatch = useDispatch();
  const { showFilters, setShowFilters } = props;
  const filter = useSelector((state) => state.supervision.filter);
  const initialFilter = useSelector((state) => state.supervision.initialFilter);
  const { data: campaignList, isFetching: isCampaignListFetching } = useCampaignListQuery();
  const { data: didList, isFetching: isDidListFetching } = useDidListQuery();
  const { data: widgetList, isFetching: isWidgetListFetching } = useWidgetListQuery();
  const { data: trackStatusList, isFetching: isTrackStatusListFetching } = useTrackStatusListQuery();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      campaign: filter?.campaign?.map((campaign) => campaignList?.data?.campaigns?.find((_campaign) => _campaign.uuid === campaign)) || initialFilter.campaign,
      did: filter?.did?.map((did) => didList?.data?.dids?.find((_did) => _did.uuid === did)) || initialFilter.did,
      widget: filter?.widget?.map((widget) => widgetList?.data?.widgets?.find((_widget) => _widget.uuid === widget)) || initialFilter.widget,
      trackStatus: filter?.trackStatus?.map((trackStatus) => trackStatusList?.data?.status?.find((_trackStatus) => _trackStatus.id === trackStatus)) || initialFilter.trackStatus,
    },
    validationSchema: Yup.object({
      campaign: Yup.array(),
      did: Yup.array(),
      widget: Yup.array(),
      trackStatus: Yup.array(),
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
        campaign: values.campaign?.map((campaign) => campaign.uuid),
        did: values.did?.map((did) => did.uuid),
        widget: values.widget?.map((widget) => widget.uuid),
        trackStatus: values.trackStatus?.map((trackStatus) => trackStatus.id)
      };
      dispatch(setFilter(payload));
      close();
    },
  });

  function close() {
    setShowFilters(false);
  }

  return (
    <Drawer
      anchor='right'
      open={showFilters}
      onClose={() => close()}
      sx={{
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 300, p: 3 },
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <TuneOutlinedIcon sx={{ mr: 1 }} />
          <Typography variant='h6'>{t('__filter')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={campaignList?.data?.campaigns || []}
            getOptionLabel={(label) => label?.name || ''}
            isOptionEqualToValue={(option, value) => option?.uuid === value?.uuid}
            loading={isCampaignListFetching}
            loadingText={t('__loading')}
            noOptionsText={t('__no_options')}
            value={formik.values.campaign || []}
            onChange={(e, value) => formik.setFieldValue(`campaign`, value)}
            renderInput={(params) => <TextField
              {...params}
              label={t('__analytics_form_campaign')}
              error={Boolean(formik.submitCount) && Boolean(formik.errors.campaign)}
              helperText={Boolean(formik.submitCount) && formik.errors.campaign}
            />}
            renderOption={(props, option) => (
              <li {...props} key={option.uuid}>
                {option.name}
              </li>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={didList?.data?.dids || []}
            getOptionLabel={(label) => label?.name || ''}
            isOptionEqualToValue={(option, value) => option?.uuid === value?.uuid}
            loading={isDidListFetching}
            loadingText={t('__loading')}
            noOptionsText={t('__no_options')}
            value={formik.values.did || []}
            onChange={(e, value) => formik.setFieldValue(`did`, value)}
            renderInput={(params) => <TextField
              {...params}
              label={t('__analytics_form_did')}
              error={Boolean(formik.submitCount) && Boolean(formik.errors.did)}
              helperText={Boolean(formik.submitCount) && formik.errors.did}
            />}
            renderOption={(props, option) => (
              <li {...props} key={option.uuid}>
                {option.name}
              </li>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={widgetList?.data?.widgets || []}
            getOptionLabel={(label) => label?.name || ''}
            isOptionEqualToValue={(option, value) => option?.uuid === value?.uuid}
            loading={isWidgetListFetching}
            loadingText={t('__loading')}
            noOptionsText={t('__no_options')}
            value={formik.values.widget || []}
            onChange={(e, value) => formik.setFieldValue(`widget`, value)}
            renderInput={(params) => <TextField
              {...params}
              label={t('__analytics_form_widget')}
              error={Boolean(formik.submitCount) && Boolean(formik.errors.widget)}
              helperText={Boolean(formik.submitCount) && formik.errors.widget}
            />}
            renderOption={(props, option) => (
              <li {...props} key={option.uuid}>
                {option.name}
              </li>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={trackStatusList?.data?.status || []}
            getOptionLabel={(label) => t(label?.name_t) || ''}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            loading={isTrackStatusListFetching}
            loadingText={t('__loading')}
            noOptionsText={t('__no_options')}
            value={formik.values.trackStatus || null}
            onChange={(e, value) => formik.setFieldValue(`trackStatus`, value)}
            renderInput={(params) => <TextField
              {...params}
              label={t('__analytics_form_track_status')}
              error={Boolean(formik.submitCount) && Boolean(formik.errors.trackStatus)}
              helperText={Boolean(formik.submitCount) && formik.errors.trackStatus}
            />}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {t(option?.name_t)}
              </li>
            )}
          />
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button sx={{ mr: 1 }} onClick={() => formik.setValues(initialFilter)}>{t("__clean")}</Button>
          <Button variant="outlined" onClick={formik.handleSubmit}>{t("__accept")}</Button>
        </Grid>

      </Grid>
    </Drawer>
  );
}

export default SupervisionFilter;
