import { createSlice } from '@reduxjs/toolkit';

const initialFilter = {
  campaign: [],
  did: [],
  widget: [],
  trackStatus: [],
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
};

const initialState = {
  initialFilter: initialFilter,
  filter: initialFilter
};

export const supervisionSlice = createSlice({
  name: 'supervision',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
});

export const { setFilter } = supervisionSlice.actions;

export const selectFilter = (state) => state.supervision.filter;
export const selectInitialFilter = (state) => state.supervision.initialFilter;

export default supervisionSlice.reducer;
