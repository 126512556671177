import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material';
import { useFormik } from 'formik';
import { useCampaignUpdateMutation } from 'services/api';
import { useTranslation } from 'react-i18next';
import ServiceForm from '../serviceForm/ServiceForm';
import * as Yup from 'yup';

function ServiceUpdate(props) {
  const { t } = useTranslation();
  const { values, open, setOpen } = props;
  const [campaignUpdate, { isLoading, error }] = useCampaignUpdateMutation();

  const formik = useFormik({
    initialValues: {
      services: '',
    },
    validationSchema: Yup.object({
      services: Yup.number().required(),
    }),
    onSubmit: (values) => {
      const payload = {
        ...values,
        domain: values.domain?.uuid,
        did: values.did?.uuid,
        schedule: values.schedule?.uuid,
        status: values.status?.id,
        widget: values.widget?.uuid
      };
      delete payload.id;
      campaignUpdate(payload).unwrap().then(() => {
        close();
      }).catch(() => { });
    },
  });

  useEffect(() => {
    if (open) formik.setValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, open]);

  function close() {
    setOpen(false);
    formik.resetForm();
  }

  return (
    <Dialog open={open} onClose={() => close()} fullWidth maxWidth="md">
      {isLoading && <LinearProgress />}
      <DialogTitle>{t("__service_update_title")}</DialogTitle>
      <DialogContent>
        <ServiceForm formik={formik} error={error?.data} />
      </DialogContent>
      <DialogActions sx={{ pr: 3, pl: 3, pb: 3 }}>
        <Button disabled={isLoading} onClick={() => close()}>{t("__cancel")}</Button>
        <Button variant="outlined" disabled={isLoading} onClick={formik.handleSubmit}>{t("__accept")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ServiceUpdate;