import { createSlice } from '@reduxjs/toolkit';
import { getTheme as getStorageTheme, setTheme as setStorageTheme } from 'services/localStorage';

const initialState = {
  language: 'es',
  theme: getStorageTheme() || 'light',
  snackbar: {
    open: false,
    message: '',
    severity: 'info', // success, error, warning, info
    autoHideDuration: 5000
  }
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
      setStorageTheme(action.payload);
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setSnackbar: (state, action) => {
      state.snackbar = { ...state.snackbar, ...action.payload };
    }
  }
});

export const { setTheme, setLanguage, setSnackbar } = appSlice.actions;

export const selectTheme = (state) => state.app.theme;
export const selectLanguage = (state) => state.app.language;

export default appSlice.reducer;
